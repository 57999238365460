import React, { FC, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

const ItemType = "ITEM";

interface Item {
    id: string;
    customName: string;
    targetLink: string;
}

interface DraggableListItemProps {
    item: Item;
    index: number;
    moveItem: (fromIndex: number, toIndex: number) => void;
    children: React.ReactNode;
    style?: React.CSSProperties;
}

const DraggableListItem: FC<DraggableListItemProps> = ({ item, index, moveItem, children, style }) => {
    const ref = useRef<HTMLDivElement>(null);

    const [, drop] = useDrop({
        accept: ItemType,
        hover(draggedItem: { index: number }) {
            if (draggedItem.index !== index) {
                moveItem(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemType,
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <div ref={ref} style={{ ...style, opacity: isDragging ? 0.5 : 1 }}>
            {children}
        </div>
    );
};

export default DraggableListItem;
