import { useTranslate } from "@pankod/refine";
import { localizationHelper } from "helpers/globalizationHelper";
import UserHelper from "helpers/userHelper";
import { FunctionComponent, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { CampaignsService } from "services";
import { CampaignManagementType, CampaignType } from "../../../Constanst";
import { Button, Space } from "antd";

interface PaymentProps {
    createdCampaignId: any;
    createdCampaignType: CampaignType;
}

const PaymentButton: FunctionComponent<PaymentProps> = (props: PaymentProps) => {
    const t = useTranslate();
    const [paymentScript, setPaymentScript] = useState<any>();
    const [campaignId, setCampaignId] = useState<string | null>(null);

    useEffect(() => {
        if (props.createdCampaignId !== null) {
            setCampaignId(props.createdCampaignId);
        }
    }, [props]);

    const defaultMessage = t("global.invoice-calculation");

    const makePayment = () => {
        if (campaignId !== null) {
            CampaignsService.makePayment({
                body: {
                    brandId: UserHelper.getId(),
                    campaignId: campaignId,
                    campaignManagementType: CampaignManagementType.SelfService,
                },
                acceptLanguage: localizationHelper.getLocale(),
            })
                .then((resp) => {
                    const script = resp.data.Id;
                    setPaymentScript(script);
                    window.open(script, "_self");
                })
                .catch((err) => console.log(err));
        }
    };

    return (
        <>
            <Helmet>
                <script type="text/javascript">{paymentScript}</script>
            </Helmet>
            <Space direction="vertical" size="large">
                <p>{t("campaign.create.brand.payment.information", defaultMessage)}</p>
                <Button onClick={makePayment} className={"ant-btn ant-btn-primary"}>
                    {t("global.make-payment")}
                </Button>
            </Space>
        </>
    );
};

export default PaymentButton;
