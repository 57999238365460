import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Col, Popover, Row, Table, useNavigation, useTranslate } from "@pankod/refine";
import { PageContainer } from "components/pageContainer/pageContainer";
import { useEffect, useState } from "react";
import { ProfileModel } from "../profile/models";
import { Pagination } from "../../services/core/Pagination";
import UserHelper from "../../helpers/userHelper";
import { CampaignsService } from "../../services";
import { localizationHelper } from "../../helpers/globalizationHelper";
import { Grid, Modal } from "antd";
import { CampaignInfluencerStates, CampaignStates, CampaignType, PaginationConstant } from "../../Constanst";
import ProfileImg from "components/microparts/profile-img";
import { Roles } from "helpers/roleHelper";
import { GetCampaignStateForInfluencer, GetCampaignTypeText } from "helpers/StateHelpers";
import { Link } from "react-router-dom";
import { Money } from "services/models/money";

const InfluencerDashboard = () => {
    const { push } = useNavigation();

    const breakpoint = Grid.useBreakpoint();
    const isMobile = !breakpoint.lg;

    const [profileData, setProfileData] = useState<ProfileModel>();
    const [campaigns, setCampaigns] = useState<any>();
    const [pagination, setPagination] = useState<Pagination>({
        current: PaginationConstant.initialPage,
        pageSize: PaginationConstant.initialPageSize,
    });

    const [dataLoading, setDataLoading] = useState(false);
    const [isApplyModalVisible, setIsApplyModalVisible] = useState(false);
    const [isApplyErrorModalVisible, setIsApplyErrorModalVisible] = useState(false);
    const [applyErrorMessage, setApplyErrorMessage] = useState("");
    const [search, setSearch] = useState<boolean>(true);

    const t = useTranslate();

    useEffect(() => {
        getProfileInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getCampaigns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const getCampaigns = () => {
        setDataLoading(true);
        CampaignsService.getCampaignInfluencersHomepage({
            body: prepareFilterRequest(),
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setDataLoading(false);
            setCampaigns(resp?.data.data);
            setPagination({
                current: resp.data.pageInfo.pageNumber,
                pageSize: resp.data.pageInfo.pageSize,
                total: resp.data.pageInfo.totalItemCount,
            });
            setSearch(false);
        });
    };

    const prepareFilterRequest = () => {
        const campaignSearch: any = {};

        campaignSearch.campaignType = 0;
        campaignSearch.campaignName = null;
        campaignSearch.startDate = null;
        campaignSearch.endDate = null;
        campaignSearch.campaignInfluencerStates = [];
        campaignSearch.campaignStates = [];
        campaignSearch.categoryIds = [];
        campaignSearch.countryIds = [];
        campaignSearch.pageNumber = pagination.current;
        campaignSearch.pageSize = pagination.pageSize;
        campaignSearch.platform = 0;
        return campaignSearch;
    };

    const apply = (campaign: any) => {
        CampaignsService.applyCampaign({
            campaignId: campaign.id,
            influencerId: UserHelper.getId(),
            acceptLanguage: localizationHelper.getLocale(),
        })
            .then((resp) => {
                if (campaign.typeHash === CampaignType.selling) {
                    setIsApplyModalVisible(true);
                    push("/campaign-influencer/detail/sale/" + campaign.id);
                } else if (campaign.typeHash === CampaignType.amazon) {
                    push("/campaign-influencer/list");
                }
            })
            .catch((err) => {
                if (campaign.typeHash === CampaignType.amazon) {
                    push("/campaign-influencer/list");
                }
                setApplyErrorMessage(err.body.message);
                setIsApplyErrorModalVisible(true);
            });
    };

    const getProfileInfo = () => {
        UserHelper.getProfile().then((resp) => {
            setProfileData(resp as ProfileModel);
        });
    };

    const handleTableChange = (page: any) => {
        setPagination({
            current: page.current,
            pageSize: page.pageSize,
            total: page.total,
        });
        setSearch(true);
    };

    const showDetail = (row: any) => {
        const hasCampaignStateModel = row.campaignStateModel !== null;
        if (hasCampaignStateModel) {
            const stateCampaign = row.campaignStateModel.campaignState;
            if (stateCampaign !== CampaignStates.WaitingForPayment) {
                const hasCampaignInfluencerStateModel = row.campaignInfluencerStateModel !== null;
                if (hasCampaignInfluencerStateModel) {
                    const stateCampaignInfluencer = row.campaignInfluencerStateModel.campaignInfluencerState;
                    if (
                        row.campaignInfluencerId != null &&
                        (stateCampaignInfluencer === CampaignInfluencerStates.Accepted ||
                            stateCampaignInfluencer === CampaignInfluencerStates.BudgetCompleted)
                    ) {
                        return "/campaign-influencer/detail/" + row.campaignInfluencerId;
                    } else {
                        return { pathname: "/campaign/detail", state: row };
                    }
                } else {
                    return { pathname: "/campaign/detail", state: row };
                }
            }
        }
        return "";
    };

    const showAction = (row: any) => {
        const campaignInfluencerState =
            row.campaignInfluencerStateModel != null ? row.campaignInfluencerStateModel.campaignInfluencerState : null;
        const campaignState = row.campaignStateModel.campaignState;

        if (row.typeHash !== CampaignType.selling && row.typeHash !== CampaignType.amazon) {
            if (
                (campaignState === CampaignStates.Accepted && campaignInfluencerState === null) ||
                (campaignState === CampaignStates.Accepted &&
                    campaignInfluencerState !== null &&
                    (campaignInfluencerState === CampaignInfluencerStates.Rejected ||
                        campaignInfluencerState === CampaignInfluencerStates.Revert))
            ) {
                return (
                    <span>
                        <Link to={showDetail(row)}>
                            <span className="clickable-span" style={{ color: "purple", textAlign: "center" }}>
                                {t("global.apply", "Başvur")}
                            </span>
                        </Link>
                    </span>
                );
            }
            return (
                <Link to={showDetail(row)}>
                    <span className="clickable-span" style={{ color: "#952AE7" }}>
                        {GetCampaignStateForInfluencer(campaignState, campaignInfluencerState)}
                    </span>
                </Link>
            );
        }

        if (row.typeHash === CampaignType.selling || row.typeHash === CampaignType.amazon) {
            if (campaignState === CampaignStates.Accepted && campaignInfluencerState === CampaignInfluencerStates.Accepted) {
                return (
                    <span>
                        <span className="clickable-span" onClick={() => apply(row)} style={{ color: "purple", textAlign: "center" }}>
                            {t("global.get-link")}
                        </span>
                    </span>
                );
            }
            if (row.typeHash === CampaignType.amazon) {
                return (
                    <span>
                        <span className="clickable-span" onClick={() => apply(row)} style={{ color: "purple", textAlign: "center" }}>
                            {t("global.get-link")}
                        </span>
                    </span>
                );
            }

            return (
                <Link to={showDetail(row)}>
                    <span className="clickable-span" style={{ color: "#952AE7" }}>
                        {GetCampaignStateForInfluencer(campaignState, campaignInfluencerState)}
                    </span>
                </Link>
            );
        }
    };

    const columns = [
        {
            title: <span className="table-font">{t("global.campaign-name")}</span>,
            dataIndex: "name",
            render: (text: any, row: any) => (
                <Link to={showDetail(row)}>
                    <span className="clickable-span" style={{ color: "#952AE7" }}>
                        {text}
                    </span>
                </Link>
            ),
        },
        {
            title: <span className="table-font">{t("global.brand", "Marka")}</span>,
            dataIndex: "brandName",
            render: (text: any, row: any) => (
                <Link to={"/brands/detail/" + row.brandId}>
                    <span className="clickable-span" style={{ color: "#952AE7" }}>
                        {text}
                    </span>
                </Link>
            ),
        },
        {
            title: <span className="table-font">{t("global.campaignType")}</span>,
            dataIndex: "campaignType",
            key: "campaignType",
            render: (text: any, row: any) => <span>{GetCampaignTypeText(row?.typeHash)}</span>,
        },
        {
            title: <span className="table-font">{t("global.start")}</span>,
            dataIndex: "startDate",
        },
        {
            title: <span className="table-font">{t("global.end")}</span>,
            dataIndex: "endDate",
            render: (text: string) => (text ? <p>{text}</p> : "-"),
        },
        {
            title: <span className="table-font">{t("campaign.create.step3.amountByInfluencerLabell")}</span>,
            dataIndex: "balancePerInfluencer",
            render: (balancePerInfluencer: number, row: any) =>
                balancePerInfluencer ? <p>{new Money(balancePerInfluencer).toStringWithCurrency(row.currency)}</p> : "-",
        },
        {
            title: <span className="table-font">{t("global.unit-price")}</span>,
            dataIndex: "unitPrice",
            render: (unitPrice: string, row: any) => <>{new Money(unitPrice).toStringWithCurrency(row.currency)}</>,
        },
        {
            title: <span className="table-font">Aksiyon</span>,
            dataIndex: "",
            key: "",
            render: (row: any) => {
                return showAction(row);
            },
        },
    ];

    const handleCancel = () => {
        setIsApplyModalVisible(false);
        setPagination({
            current: PaginationConstant.initialPage,
            pageSize: PaginationConstant.initialPageSize,
        });
        setSearch(true);
    };

    const handleApplyErrorCancel = () => {
        setIsApplyErrorModalVisible(false);
    };

    return (
        <>
            <Modal visible={isApplyModalVisible} centered footer={[]} onCancel={handleCancel}>
                <div style={{ textAlign: "center" }}>
                    <h3 style={{ fontWeight: "bold", color: "#952AE7", fontSize: "20px" }}>{t("global.campaignGet")}</h3>
                    <p>{t("global.accountCheck")}</p>
                </div>
            </Modal>
            <Modal visible={isApplyErrorModalVisible} centered footer={[]} onCancel={handleApplyErrorCancel}>
                <div style={{ textAlign: "center" }}>
                    <h3 style={{ fontWeight: "bold", color: "#952AE7", fontSize: "20px" }}>{t("global.campaign-apply-error")}</h3>
                    <p>{applyErrorMessage}</p>
                </div>
            </Modal>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} span={12}>
                    <PageContainer
                        pageTitle={t("global.my-profile")}
                        helperContent={t("global.influencer.homepage.helper-content.profile-info")}
                    >
                        <div className={"dashboard-profile"}>
                            <Row align="middle">
                                <Col>
                                    <ProfileImg type={Roles.Influencer} alt="Profil" width={128} height={128} />
                                </Col>
                                <Col style={{ padding: "0px 0px 0px 20px" }}>
                                    <h2 style={{ color: "#9254de", fontWeight: "bold" }}>
                                        {profileData?.name} {profileData?.surname}
                                    </h2>
                                    <p>
                                        <span>
                                            {profileData?.categories &&
                                                profileData.categories.length > 0 &&
                                                profileData.categories.map((c) => "#" + c.name).join(" ")}
                                        </span>
                                        / {profileData?.country}
                                    </p>
                                    <p>{profileData?.createdOn}</p>
                                </Col>
                            </Row>
                            <Row justify="end">
                                <Link to="profile/edit">
                                    <Button type="ghost">{t("global.profileEdit")}</Button>
                                </Link>
                            </Row>
                        </div>
                    </PageContainer>
                </Col>
                <Col xs={24} sm={24} md={12} span={12}>
                    <PageContainer
                        pageTitle={t("global.wallet")}
                        helperContent={t("global.influencer.homepage.helper-content.wallet-info")}
                    >
                        <div className="dashboard-campaign-create">
                            <div className="info-text mb-15">
                                <h4 className="title">
                                    {t("global.balance")}{" "}
                                    <Popover
                                        content={t("global.influencer.wallet.helper-content.balance")}
                                        placement="bottomRight"
                                        title={t("global.balance")}
                                        trigger={isMobile ? "click" : "hover"}
                                    >
                                        <InfoCircleOutlined />
                                    </Popover>
                                </h4>
                            </div>
                            <div className="body">
                                <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                                    {new Money(profileData?.balanceDecimal).toStringWithCurrency(profileData?.currency)}
                                </span>
                            </div>
                            <div className="info-text mb-15 mt-25">
                                <h4 className="title">
                                    {t("global.payThisMonth")}{" "}
                                    <Popover
                                        content={t("global.influencer.wallet.helper-content.balance-on-month")}
                                        placement="bottomRight"
                                        title={t("global.payThisMonth")}
                                        trigger={isMobile ? "click" : "hover"}
                                    >
                                        <InfoCircleOutlined />
                                    </Popover>
                                </h4>
                            </div>
                            <div className="body">
                                <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                                    {new Money(profileData?.balanceOnMonthDecimal).toStringWithCurrency(profileData?.currency)}
                                </span>
                            </div>
                            <Row justify="end" style={{ padding: "70px 0px 0px 0px" }}>
                                <Link to={"profile/editPayment"}>
                                    <Button type="ghost">
                                        {profileData?.billingDetail ? (
                                            <span>{t("global.payment-information-update")}</span>
                                        ) : (
                                            <span>{t("global.payment-information-add")}</span>
                                        )}
                                    </Button>
                                </Link>
                            </Row>
                        </div>
                    </PageContainer>
                </Col>
            </Row>
            <Row style={{ width: "100%", marginTop: "20px", marginBottom: "70px" }}>
                <Col span={24}>
                    <PageContainer
                        pageTitle={t("global.campaigns")}
                        helperContent={t("global.influencer.homepage.helper-content.campaigns-info")}
                    >
                        <Table
                            style={{ overflow: "scroll" }}
                            pagination={pagination}
                            columns={columns}
                            dataSource={campaigns}
                            onChange={handleTableChange}
                            loading={dataLoading}
                        />
                    </PageContainer>
                </Col>
            </Row>
        </>
    );
};

export default InfluencerDashboard;
