import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const Footer: React.FC = () => {
    const { i18n } = useTranslation();

    useEffect(() => {
        // TODO figure out how to do this using react
        const searchParams = new URLSearchParams(window.location.search);
        const lang = searchParams.get("lang");
        const acceptedLang = ["en", "es", "tr"];
        if (lang && acceptedLang.includes(lang)) {
            i18n.changeLanguage(lang);
        }
    }, [i18n]);

    return null;
};
