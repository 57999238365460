import { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { List, Skeleton, Divider, notification, Checkbox, Grid } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { Modal, Col, Row, Button, Input, useTranslate } from "@pankod/refine";
import { CampaignsService } from "../../../../services";
import { localizationHelper } from "helpers/globalizationHelper";
import DraggableListItem from "./DraggableListItem";

export interface InfinityListProps {
    datas: any[];
    refresh: any;
}

interface Item {
    id: string;
    customName: string;
    targetLink: string;
}

export const InfiniteList = (props: InfinityListProps) => {
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedLink, setSelectedLink] = useState<any>(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [changedName, setChangedName] = useState(selectedLink?.customName);
    const [changedLink, setChangedLink] = useState(selectedLink?.targetLink);
    const [items, setItems] = useState<Item[]>([]);
    const [isSelectMode, setIsSelectMode] = useState(false);
    const [checkedItems, setCheckedItems] = useState<string[]>([]);
    const t = useTranslate();

    useEffect(() => {
        if (selectedLink) {
            setChangedLink(selectedLink.targetLink || "");
            setChangedName(selectedLink.customName || "");
        }
    }, [selectedLink]);

    useEffect(() => {
        setItems(props.datas);
    }, [props.datas]);

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const normalizedValue = normalizeTurkishChars(value);
        setSearchQuery(normalizedValue);
    };

    const normalizeTurkishChars = (text: string) => {
        return text.replace(/ı/g, "i").replace(/İ/g, "I").toLowerCase();
    };

    const filteredData = props.datas.filter((item) => {
        const customName = item.customName || "";
        return customName.toLowerCase().includes(searchQuery.toLowerCase());
    });

    const copyToClipboard = (link: string) => {
        const textField = document.createElement("textarea");
        textField.innerText = link;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();
        notification.open({ message: t("global.copied"), type: "success" });
    };

    const loadMoreData = () => {
        if (!props.datas) {
            setLoading(true);
        }
        setLoading(false);
    };

    useEffect(() => {
        loadMoreData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeLinkName = () => {
        CampaignsService.updateCampaignLink({
            id: selectedLink.id,
            body: {
                name: changedName,
                originalLink: changedLink,
            },
            acceptLanguage: localizationHelper.getLocale(),
        }).then((res) => {
            setSelectedLink(null);
            setIsModalVisible(false);
            props.refresh();
        });
    };

    const handleDeleteLink = () => {
        CampaignsService.deleteCampaignLinks({
            body: [selectedLink.id],
            acceptLanguage: localizationHelper.getLocale(),
        }).then(() => {
            setSelectedLink(null);
            setIsModalVisible(false);
            setChangedName("");
            props.refresh();
        });
    };

    const reorderLinks = (id: string, newOrder: number) => {
        CampaignsService.reorderCampaignLinks({
            body: {
                id: id,
                newOrder: newOrder,
            },
            acceptLanguage: localizationHelper.getLocale(),
        })
            .then((res) => {
                props.refresh();
            })
            .catch((error) => {
                console.error("Error reordering campaign links:", error);
            });
    };

    const moveItem = (fromIndex: number, toIndex: number) => {
        if (fromIndex === toIndex) return;

        const updatedItems = [...items];
        const [movedItem] = updatedItems.splice(fromIndex, 1);
        updatedItems.splice(toIndex, 0, movedItem);
        setItems(updatedItems);

        reorderLinks(movedItem.id, toIndex);
    };

    const handleCheckboxChange = (id: string) => {
        if (checkedItems.includes(id)) {
            setCheckedItems(checkedItems.filter((item) => item !== id));
        } else {
            setCheckedItems([...checkedItems, id]);
        }
    };

    const handleDelete = () => {
        CampaignsService.deleteCampaignLinks({
            body: checkedItems,
            acceptLanguage: localizationHelper.getLocale(),
        })
            .then(() => {
                setIsDeleteModalVisible(false);
                setCheckedItems([]);
                props.refresh();
            })
            .catch((error) => {
                console.error("Error deleting campaign links:", error);
            });
    };

    const breakpoint = Grid.useBreakpoint();
    const isMobile = !breakpoint.lg;

    return (
        <div
            style={{
                height: 330,
                overflow: "hidden",
                padding: "0 25px",
                border: "1px solid #952AE7",
                width: "100%",
                borderRadius: "14px",
            }}
        >
            <Row style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                <Input
                    placeholder={t("global.searchByLinkName")}
                    value={searchQuery}
                    onChange={handleSearch}
                    style={{ marginBottom: 20, marginTop: 25, width: isMobile ? "40%" : "40%", fontSize: isMobile ? "12px" : "14px" }}
                    prefix={<SearchOutlined style={{ fontSize: "16px", color: "#952AE7" }} />}
                />
                {!isSelectMode ? (
                    <Button
                        onClick={() => setIsSelectMode(true)}
                        style={{ backgroundColor: "transparent", color: "#D02D7E", fontWeight: 700, fontSize: isMobile ? "14px" : "16px" }}
                    >
                        {t("buttons.select")}
                    </Button>
                ) : (
                    <div>
                        <Button
                            onClick={() => {
                                setIsDeleteModalVisible(true);
                            }}
                            style={{
                                backgroundColor: "transparent",
                                color: checkedItems.length === 0 ? "#A9A9A9" : "#F03738",
                                fontWeight: 700,
                                fontSize: isMobile ? "14px" : "16px",
                            }}
                            disabled={checkedItems.length === 0}
                        >
                            {t("buttons.delete")}
                        </Button>
                        <Button
                            onClick={() => {
                                setIsSelectMode(false);
                                setCheckedItems([]);
                            }}
                            style={{
                                backgroundColor: "transparent",
                                color: "#D02D7E",
                                fontWeight: 700,
                                fontSize: isMobile ? "14px" : "16px",
                            }}
                        >
                            {t("buttons.cancel")}
                        </Button>
                    </div>
                )}
            </Row>

            <div
                id="scrollableDiv"
                style={{
                    height: 330,
                    overflow: "auto",
                    width: "100%",
                    borderRadius: "14px",
                }}
            >
                <InfiniteScroll
                    dataLength={filteredData.length}
                    next={loadMoreData}
                    hasMore={loading}
                    loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                    endMessage={<Divider plain />}
                    scrollableTarget="scrollableDiv"
                >
                    <List
                        dataSource={filteredData}
                        renderItem={(item, index) => (
                            <DraggableListItem
                                key={item.id}
                                index={index}
                                item={item}
                                moveItem={moveItem}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {isSelectMode && (
                                    <Checkbox
                                        style={{ marginRight: 10 }}
                                        checked={checkedItems.includes(item.id)}
                                        onChange={() => handleCheckboxChange(item.id)}
                                        className="custom-checkbox"
                                    />
                                )}
                                <Row
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        width: "100%",
                                        backgroundColor: checkedItems.includes(item.id) ? "#F4EAFD" : "#FAF4FE",
                                        marginBottom: "10px",
                                        boxShadow: "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px",
                                        borderRadius: 8,
                                        height: 40,
                                    }}
                                >
                                    <Row style={{ display: "flex", gap: "10px", marginLeft: 10, width: "45%" }}>
                                        <Col>
                                            <img src="/images/move.svg" alt="move icon" />
                                        </Col>
                                        <Col style={{ width: "70%" }}>
                                            <Row
                                                style={{
                                                    color: "#000000",
                                                    fontWeight: 600,
                                                    fontSize: isMobile ? 12 : 16,
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    maxWidth: isMobile ? "90%" : "80%",
                                                }}
                                            >
                                                {item.customName}
                                            </Row>
                                            {isMobile && (
                                                <Row
                                                    style={{
                                                        color: "#565C66",
                                                        fontWeight: 600,
                                                        fontSize: isMobile ? 10 : 14,
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        maxWidth: "100%",
                                                    }}
                                                >
                                                    {item.targetLink}
                                                </Row>
                                            )}
                                        </Col>
                                    </Row>

                                    <Row style={{ display: "flex", gap: "19px", marginRight: 10 }}>
                                        {!isMobile && (
                                            <Col
                                                style={{
                                                    color: "#565C66",
                                                    fontWeight: 600,
                                                    fontSize: 14,
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    maxWidth: "80%",
                                                }}
                                            >
                                                {item.targetLink}
                                            </Col>
                                        )}

                                        <Col onClick={() => copyToClipboard(item.targetLink)} style={{ cursor: "pointer" }}>
                                            <img
                                                src="/images/copy.svg"
                                                alt="copy icon"
                                                width={isMobile ? 15 : 18}
                                                height={isMobile ? 15 : 18}
                                            />
                                        </Col>
                                        <Col
                                            onClick={() => {
                                                setSelectedLink(item);
                                                setIsModalVisible(true);
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <img
                                                src="/images/edit.svg"
                                                alt="edit icon"
                                                width={isMobile ? 15 : 18}
                                                height={isMobile ? 15 : 18}
                                            />
                                        </Col>
                                    </Row>
                                </Row>
                            </DraggableListItem>
                        )}
                    />
                </InfiniteScroll>
                <Modal visible={isModalVisible} centered footer={[]} onCancel={handleCancel} closable={false}>
                    <Row gutter={[0, 10]} style={{ display: "flex", flexDirection: "column" }}>
                        <Row style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Col style={{ fontWeight: "bold", fontSize: "32px", color: "#6F2FF2" }}>{t("global.editLink")}</Col>
                            <Col onClick={handleCancel} style={{ color: "#6F2FF2" }}>
                                <CloseOutlined size={24} onClick={() => setIsModalVisible(false)} />
                            </Col>
                        </Row>
                        <Col>
                            <Row>{t("global.link")}</Row>
                            <Input value={changedLink} onChange={(e) => setChangedLink(e.target.value)} disabled />
                        </Col>
                        <Col>
                            <Row>{t("global.opsionalLinkname")}</Row>
                            <Input value={changedName} onChange={(e) => setChangedName(e.target.value)} placeholder={changedName} />
                        </Col>
                        <Row style={{ marginTop: 30, display: "flex", justifyContent: "space-between" }}>
                            <Button onClick={handleDeleteLink} style={{ backgroundColor: "#F03738", width: "48%", color: "white" }}>
                                {t("buttons.delete")}
                            </Button>
                            <Button
                                disabled={!changedLink}
                                onClick={changeLinkName}
                                style={{ backgroundColor: changedLink ? "#6F2FF2" : "#CED4DA", width: "48%", color: "white" }}
                            >
                                {t("buttons.save")}
                            </Button>
                        </Row>
                    </Row>
                </Modal>
                <Modal visible={isDeleteModalVisible} centered footer={false} closable={false}>
                    <Row gutter={[0, 10]} style={{ display: "flex", flexDirection: "column", color: "#6F2FF2" }}>
                        <Row style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Col style={{ fontWeight: "bold", fontSize: "32px" }}>{t("global.deleteLinks")}</Col>
                            <Col onClick={handleCancel}>
                                <CloseOutlined size={24} onClick={() => setIsDeleteModalVisible(false)} color="#6F2FF2" />
                            </Col>
                        </Row>
                        <Row style={{ color: "#323A46" }}> {t("global.linksInaccessibleAfterDelete")}</Row>
                        <Row style={{ marginTop: 40, display: "flex", justifyContent: "space-between" }}>
                            <Button onClick={handleDelete} style={{ backgroundColor: "#F03738", width: "48%" }}>
                                {t("buttons.delete")}
                            </Button>
                            <Button onClick={() => setIsDeleteModalVisible(false)} style={{ backgroundColor: "#6F2FF2", width: "48%" }}>
                                {t("buttons.cancel")}
                            </Button>
                        </Row>
                    </Row>
                </Modal>
            </div>
            <style>
                {`
                  .custom-checkbox .ant-checkbox-inner {
                   background-color: #FBF6FE;
                   border-color: #D02D7E;
                }

                 .custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
                    background-color: #FBF6FE;
                    border-color: #D02D7E;
                }

                .custom-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: 40%;
                    width: 5px;
                    height: 10px;
                    border: solid #6F2FF2;
                    border-width: 0 2px 2px 0;
                    transform: translate(-50%, -50%) rotate(45deg);
                }
                `}
            </style>
        </div>
    );
};
