import { Button, Divider, Form, Input, useNavigation } from "@pankod/refine";
import { Col, Row } from "antd";
import { AllowedRoles } from "helpers/AllowedRoles";
import { Roles } from "helpers/roleHelper";
import { PageContainer } from "../../components/pageContainer/pageContainer";
import UserHelper from "../../helpers/userHelper";
import { useEffect, useState } from "react";
import { Table } from "antd";
import { Pagination } from "../../services/core/Pagination";
import { findSocialMediaById, PaginationConstant, SocialMedias } from "../../Constanst";
import { InfluencersService } from "../../services";
import { localizationHelper } from "../../helpers/globalizationHelper";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const DashboardPage = () => {
    const { push } = useNavigation();
    const [pagination, setPagination] = useState<Pagination>({
        current: PaginationConstant.initialPage,
        pageSize: 5,
    });

    const [dataLoading, setDataLoading] = useState(false);
    const [influencerIdFromSocialMedia, setInfluencerIdFromSocialMedia] = useState<string>("");
    const [influencerSocialMediaDetail, setInfluencerSocialMediaDetail] = useState<any>(undefined);
    const [socialMediaQueryFormFinished, setSocialMediaQueryFormFinished] = useState(false);
    const [data, setData] = useState<any>();
    const [filterValues] = useState<any>();
    const role = UserHelper.getRole();
    const { t } = useTranslation();
    const [profile, setProfile] = useState<any>({});
    const [search, setSearch] = useState<boolean>(true);

    const showInfluencerDetail = (id: any) => {
        push("/influencers/detail/" + id);
    };

    useEffect(() => {
        UserHelper.getProfile().then((resp) => {
            setProfile(resp);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        });
    }, []);

    const approve = (row: any) => {
        setDataLoading(true);
        InfluencersService.approveInfluencerSocialMedias({ id: row.id, acceptLanguage: localizationHelper.getLocale() }).then((resp) => {
            setPagination({
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: resp?.data?.pageInfo.totalItemCount,
            });
            setDataLoading(false);
            setSearch(true);
        });
    };

    const reject = (row: any) => {
        setDataLoading(true);
        InfluencersService.rejectInfluencerSocialMedias({ id: row.id, acceptLanguage: localizationHelper.getLocale() }).then((resp) => {
            setPagination({
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: resp?.data?.pageInfo.totalItemCount,
            });
            setDataLoading(false);
            setSearch(true);
        });
    };

    useEffect(() => {
        if (search) getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const columns = [
        {
            dataIndex: "influencerName",
            render: (text: string) => (
                <p style={{ textAlign: "center", color: "#9254de", fontWeight: "bold" }}>
                    <span>{text}</span>
                </p>
            ),
            width: 200,
        },
        {
            dataIndex: "socialMedia",
            render: (text: string) => {
                if (text === SocialMedias.instagram.name) {
                    return <img alt={"social"} src="/images/social-media-icon/INSTAGRAM36Px.svg" />;
                } else if (text === SocialMedias.twitter.name) {
                    return <img alt={"social"} src="/images/social-media-icon/TWITTER36Px.svg" />;
                } else if (text === SocialMedias.youtube.name) {
                    return <img alt={"social"} src="/images/social-media-icon/YOUTUBE36Px.svg" />;
                } else if (text === SocialMedias.tiktok.name) {
                    return <img alt={"social"} src="/images/social-media-icon/TIKTOK36Px.svg" />;
                } else if (text === SocialMedias.twitch.name) {
                    return <img alt={"social"} src="/images/social-media-icon/TWITCH36Px.svg" />;
                } else if (text === SocialMedias.telegram.name) {
                    return <img alt={"social"} src="/images/social-media-icon/TELEGRAM36Px.svg" />;
                } else if (text === SocialMedias.discord.name) {
                    return <img alt={"social"} src="/images/social-media-icon/DISCORD36Px.svg" />;
                }
            },
            width: 50,
        },
        {
            dataIndex: "link",
            render: (text: string, row: any) => {
                return (
                    <p style={{ textAlign: "center", color: "#9254de", fontWeight: "bold" }}>
                        <a href={text} style={{ color: "#9254de" }} target="_blank" rel="noreferrer">
                            @{row.username}
                        </a>
                    </p>
                );
            },
            width: 150,
        },
        {
            dataIndex: "followers",
            render: (text: string) => (
                <p style={{ textAlign: "center" }}>
                    <span>{text}</span>
                </p>
            ),
            width: 60,
        },
        {
            dataIndex: "pendingApprovalFollowers",
            render: (text: string) => (
                <p style={{ textAlign: "center", color: "#9254de" }}>
                    <span>{text}</span>
                </p>
            ),
            width: 60,
        },
        {
            dataIndex: "reject",
            render: (text: string, row: any) => (
                <p style={{ textAlign: "center", fontWeight: "bold" }}>
                    <span className="clickable-span" style={{ color: "#FF0C85" }} onClick={() => reject(row)}>
                        {t("global.reject", "Reddet")}
                    </span>
                </p>
            ),
            width: 40,
        },
        {
            dataIndex: "approve",
            render: (text: string, row: any) => (
                <p style={{ textAlign: "center", fontWeight: "bold" }}>
                    <span className="clickable-span" style={{ color: "#3CC13B" }} onClick={() => approve(row)}>
                        {t("global.accept", "Kabul Et")}
                    </span>
                </p>
            ),
            width: 40,
        },
    ];

    const getData = () => {
        setDataLoading(true);
        InfluencersService.searchInfluencerSocialMedias({
            requestBody: prepareFilterRequest(),
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setDataLoading(false);
            setData(resp?.data?.data);
            setPagination({
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: resp?.data?.pageInfo.totalItemCount,
            });
            setSearch(false);
        });
    };

    const prepareFilterRequest = () => {
        const auditLogSearch: any = {};
        if (filterValues) {
            auditLogSearch.isApproved = filterValues.isApproved ?? null;
            auditLogSearch.isDeleted = filterValues.isDeleted ?? null;
            auditLogSearch.isDeleted = pagination.current;
            auditLogSearch.pageSize = pagination.pageSize;
        } else {
            auditLogSearch.isApproved = false;
            auditLogSearch.isDeleted = false;
            auditLogSearch.pageNumber = pagination.current;
            auditLogSearch.pageSize = pagination.pageSize;
        }

        return auditLogSearch;
    };

    const handleTableChange = (page: any) => {
        setPagination({
            current: page.current,
            pageSize: page.pageSize,
            total: page.total,
        });
        setSearch(true);
    };

    const influencerSocialMediaFormFinishedHandler = (formParams: any) => {
        InfluencersService.getInfluencerSocialMedia({
            id: formParams.clickmeterInput.toString(),
            acceptLanguage: localizationHelper.getLocale(),
        })
            .then((response) => {
                setInfluencerIdFromSocialMedia(response?.data?.influencerId);
                if (response?.data?.createInfluencerSocialMediaDtos && response?.data?.createInfluencerSocialMediaDtos.length > 0) {
                    const detailObj = response.data.createInfluencerSocialMediaDtos[0];
                    setInfluencerSocialMediaDetail(detailObj);
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error("pages.error.notFound");
            });
        setSocialMediaQueryFormFinished(true);
    };

    const influencerLinkFormFinishedHandler = (formParams: any) => {
        push("/influencers/detail/" + formParams.influencerIdInput + "/links");
    };

    return (
        <AllowedRoles roles={[Roles.Admin, Roles.Influencer, Roles.Brand, Roles.Root]}>
            <Row gutter={[12, 12]}>
                <Col xs={24} sm={24} md={12} span={12}>
                    <PageContainer pageTitle={t("global.profile")} helperContent={"Yardım içeriği eklenecek!"}>
                        <div className="dashboard-profile">
                            <div className="info-text mb-25">
                                <h4>
                                    {profile?.firstName} {profile?.lastName}
                                </h4>
                                <span>{role}</span>
                            </div>
                            <Row className="mt-25">
                                <div className="info-text">
                                    <h1>
                                        <b>Id</b>
                                    </h1>
                                    <span>#{profile?.id}</span>
                                </div>
                            </Row>
                            <Row className="mt-25">
                                <div className="info-text">
                                    <h1>
                                        <b>{t("global.name-surname")}</b>
                                    </h1>
                                    <span>
                                        {profile?.firstName} {profile?.lastName}
                                    </span>
                                </div>
                            </Row>
                            <Row className="mt-25 mb-25">
                                <div className="info-text">
                                    <h1>
                                        <b>{t("pages.login.email")}</b>
                                    </h1>
                                    <span>{profile?.email}</span>
                                </div>
                            </Row>
                        </div>
                    </PageContainer>
                    <div className={"mt-25 mb-25"}>
                        <a href="/campaign/create" className="clickable-span">
                            <PageContainer pageTitle={t("campaign.create.pageTitle")} helperContent={"Yardım içeriği eklenecek!"}>
                                <div className="dashboard-campaign-create">
                                    <div className="info-text mb-15">
                                        <h4 className="title" style={{ cursor: "pointer" }}>
                                            <u>{t("global.admin.dashboard.create-new-campaign.title")}</u>
                                        </h4>
                                    </div>
                                    <div className="body">
                                        <span>{t("global.admin.dashboard.create-new-campaing-content")}</span>
                                    </div>
                                </div>
                            </PageContainer>
                        </a>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={12} span={12}>
                    <PageContainer
                        pageTitle={t("campaign.create.step2.changespendingapproval")}
                        helperContent={"Yardım içeriği eklenecek!"}
                    >
                        <div className="dashboard-approve-change">
                            <div>
                                <Table
                                    columns={columns}
                                    dataSource={data}
                                    pagination={pagination}
                                    size="middle"
                                    scroll={{ x: 700 }}
                                    onChange={(page) => handleTableChange(page)}
                                    loading={dataLoading}
                                />
                            </div>
                            <Divider></Divider>
                            <div>
                                <div className="info-text mb-15">
                                    <h4 className="title">
                                        <u>{t("global.find-clickmeter-link-title")}</u>
                                    </h4>
                                </div>
                                <div>
                                    <span>{t("global.find-clickmeter-link-description")}</span>
                                    <Form layout="vertical" onFinish={influencerSocialMediaFormFinishedHandler}>
                                        <Form.Item
                                            label={t("global.find-clickmeter-link-input-label")}
                                            name="clickmeterInput"
                                            rules={[
                                                {
                                                    required: true,
                                                    min: 16,
                                                    max: 100,
                                                    type: "string",
                                                    message: t("global.generic-validation-error"),
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button htmlType="submit" style={{ marginLeft: "auto" }} type="primary">
                                                {t("buttons.show")}
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                    {socialMediaQueryFormFinished && (
                                        <div>
                                            <div className="info-text">
                                                <h1>
                                                    <b>{t("global.influencer-id")}</b>
                                                </h1>
                                                <span
                                                    className="clickable-span"
                                                    onClick={() => {
                                                        showInfluencerDetail(influencerIdFromSocialMedia);
                                                    }}
                                                >
                                                    {influencerIdFromSocialMedia}
                                                </span>
                                            </div>
                                            <br></br>
                                            <Row>
                                                <Col xs={12}>
                                                    <div className="info-text">
                                                        <h1>
                                                            <b>{t("global.social-media")}</b>
                                                        </h1>
                                                        <span>
                                                            {findSocialMediaById(Number.parseInt(influencerSocialMediaDetail?.name))?.name}
                                                        </span>
                                                    </div>
                                                </Col>
                                                <Col xs={12}>
                                                    <div className="info-text">
                                                        <h1>
                                                            <b>{t("global.social-media-account")}</b>
                                                        </h1>
                                                        <span>{influencerSocialMediaDetail?.username}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <Divider></Divider>
                            <div>
                                <div className="info-text mb-15">
                                    <h4 className="title">
                                        <u>{t("global.find-links-from-influencers-title")}</u>
                                    </h4>
                                </div>
                                <div>
                                    <span>{t("global.find-links-from-influencers-description")}</span>
                                    <Form layout="vertical" onFinish={influencerLinkFormFinishedHandler}>
                                        <Form.Item
                                            label={t("Fenom")}
                                            name="influencerIdInput"
                                            rules={[
                                                {
                                                    required: true,
                                                    min: 16,
                                                    max: 100,
                                                    type: "string",
                                                    message: t("global.generic-validation-error"),
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button htmlType="submit" style={{ marginLeft: "auto" }} type="primary">
                                                {t("buttons.next")}
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </PageContainer>
                </Col>
            </Row>
        </AllowedRoles>
    );
};
