import { Button, Col, Modal, Row, Typography } from "antd";
import { FC } from "react";
import { useMultiplePaymentApprovement } from "./useMultiplePaymentApprovement";
import { useTranslate } from "@pankod/refine";
import { Money } from "services/models/money";

const { Paragraph } = Typography;

export interface MultiplePaymentApprovementDetail {
    influencerList: string[];
    selectedPaymentAmount: number;
}
export const MultiplePaymentApprovement: FC = () => {
    const [open, detail, handleClose, handleOk, loading] = useMultiplePaymentApprovement<MultiplePaymentApprovementDetail>();

    const t = useTranslate();
    return (
        <Modal open={open} confirmLoading={loading} closable={false} footer={null} width={500}>
            <Row justify="space-between">
                <Col>
                    <h1 style={{ textAlign: "center", fontSize: "28px", color: "#952AE7" }}>
                        <b>{t("global.payment-confirmation")}</b>
                    </h1>
                </Col>
                <Col>
                    <Button type="link" onClick={handleClose} style={{ padding: "0px" }}>
                        <img src="/images/close.svg" alt="close icon" />
                    </Button>
                </Col>
            </Row>
            <Typography>
                <Paragraph style={{ fontSize: 16, fontWeight: 600 }}>
                    {detail?.influencerList?.length} fenomene {new Money(detail?.selectedPaymentAmount).toStringWithCurrency()} ödeme
                    yapmayı onaylıyor musunuz?

                </Paragraph>
            </Typography>
            <Row justify="space-between" style={{ marginTop: "30px" }}>
                <Col>
                    <Button type="default" onClick={handleOk} style={{ width: "211px" }}>
                        {t("buttons.accept2")}
                    </Button>
                </Col>
                <Col>
                    <Button type="primary" onClick={handleClose} style={{ width: "211px" }}>
                        {t("global.giveup")}
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
};
