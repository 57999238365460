import { useTranslate } from "@pankod/refine";
import { Row, Col, Form, Space, Input, Select, Checkbox, Button } from "antd";
import { FC, useState } from "react";
import { useFilter } from "./useFilter";
import { CaretDownOutlined } from "@ant-design/icons";

export const Filter: FC = () => {
    const [handleFilter, countries] = useFilter();
    const t = useTranslate();
    const [open, setOpen] = useState(false);

    const [form] = Form.useForm();

    const onReset = () => {
        form.resetFields();
    };

    return (
        <Row>
            <Col style={{ fontSize: "small" }} span={24}>
                <Form name="basic" layout="vertical" form={form} onFinish={handleFilter} autoComplete="off">
                    <Space direction="vertical" size={1} style={{ width: "100%" }}>
                        <Row gutter={[32, 8]}>
                            <Col xs={24} sm={24} md={8} span={8}>
                                <Form.Item label={t("global.influencer-id", "Fenomen ID")} name="influencerId">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={8} span={8}>
                                <Form.Item label={t("global.influencer-name", "Fenomen")} name="firstName">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={8} span={8}>
                                <Form.Item label={t("global.country")} name="countries">
                                    <Select
                                        allowClear
                                        mode={"multiple"}
                                        suffixIcon={
                                            <CaretDownOutlined
                                                style={{
                                                    fontSize: "15px",
                                                    color: "#952AE7",
                                                }}
                                                onClick={() => setOpen(!open)}
                                            />
                                        }
                                        showArrow
                                        options={countries.map((country) => ({
                                            label: country.text,
                                            value: country.id,
                                        }))}
                                        open={open}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={6} span={6}>
                                <Form.Item name="listWaitingPaymentInfluencers" valuePropName="checked">
                                    <Checkbox>{t("global.payThisMonth-list")}</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: "60px" }}>
                            <Col style={{ marginRight: "auto" }}>
                                <Form.Item>
                                    <Button htmlType={"submit"} type="default">
                                        {t("buttons.filter")}
                                    </Button>
                                    <Button type="link" onClick={onReset}>
                                        {t("buttons.clearAll")}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Space>
                </Form>
            </Col>
        </Row>
    );
};
