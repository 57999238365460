import { Avatar, Button, Checkbox, Col, Row, Space, useNavigation, useTranslate } from "@pankod/refine";
import { BrandStatusColors } from "Constanst";
import { PageContainer } from "components/pageContainer/pageContainer";
import UserHelper from "helpers/userHelper";
import { IBrand } from "modules/brands/model";

interface DisplayFormProps {
    brand: IBrand;
    setDisplaying: (value: boolean) => void;
}

export const DisplayForm = (props: DisplayFormProps) => {
    const { push } = useNavigation();
    const t = useTranslate();

    const brand = props.brand;

    return (
        <PageContainer pageTitle={"" + brand.name} helperContent={""}>
            <Row gutter={[30, 0]}>
                <Col>
                    <Avatar
                        shape="square"
                        size={128}
                        src={UserHelper.getLogoPath() ?? "/images/profile/profile_brand.png"}
                        style={{ marginBottom: "20px" }}
                    />
                </Col>
                <Col>
                    <Col>
                        <h3 style={{ fontWeight: "bold", color: BrandStatusColors[brand.status - 1] }}>
                            {t(`global.brand.status.${brand.status}`)}
                        </h3>
                    </Col>
                    <Col style={{ padding: "50px 0px 0px 20px" }}>
                        <h2 style={{ color: "#9254de", fontWeight: "bold" }}>{brand.name}</h2>
                        <p>
                            {brand.sector} /{brand.country}
                        </p>
                        <p>{brand.recordDate}</p>
                    </Col>
                </Col>
            </Row>
            <hr style={{ borderTop: "1px solid #952AE7" }} />
            <Row>
                <Col xs={24} sm={24} md={6} span={6}>
                    <Row>
                        <h3 style={{ fontWeight: "bold" }}>Id</h3>
                    </Row>
                    <Row>
                        <p>{brand.id}</p>
                    </Row>
                    <Row>
                        <h3 style={{ fontWeight: "bold" }}>{t("global.brand", "Marka")}</h3>
                    </Row>
                    <Row>
                        <p>{brand.name}</p>
                    </Row>
                    <Row>
                        <h3 style={{ fontWeight: "bold" }}>{t("global.country", "Ülke")}</h3>
                    </Row>
                    <Row>
                        <p>{brand.country}</p>
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={6} span={6}>
                    <Row>
                        <h3 style={{ fontWeight: "bold" }}>{t("global.phone", "Telefon")}</h3>
                    </Row>
                    <Row>
                        <p>{brand.phone}</p>
                    </Row>
                    <Row>
                        <h3 style={{ fontWeight: "bold" }}>{t("global.email", "E-posta")}</h3>
                    </Row>
                    <Row>
                        <p>{brand.email}</p>
                    </Row>
                    <Row>
                        <Checkbox checked={brand.hasMarketingPermission}>
                            <h3 style={{ fontWeight: "bold" }}>{t("pages.brand.hasMarketingPermission")}</h3>
                        </Checkbox>
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={12} span={6}>
                    <Row>
                        <h3 style={{ fontWeight: "bold" }}>{t("pages.brand.descriptionLabel")}</h3>
                    </Row>
                    <Row>
                        <p>{brand.detail}</p>
                    </Row>
                    <Row>
                        <h3 style={{ fontWeight: "bold" }}>{t("pages.brand.brandDetailLabel")}</h3>
                    </Row>
                    <Row>
                        <p>{brand.workingDetail}</p>
                    </Row>
                </Col>
            </Row>

            <Space direction="horizontal" size={"middle"} style={{ width: "100%", justifyContent: "space-between" }}>
                <Button
                    type="ghost"
                    onClick={() => {
                        push(`/brands/detail/${brand.id}/receipt`);
                    }}
                >
                    {" "}
                    {t("global.recieptView")}{" "}
                </Button>

                <Button
                    type="ghost"
                    onClick={() => {
                        props.setDisplaying(false);
                    }}
                >
                    {" "}
                    {t("buttons.edit")}{" "}
                </Button>
            </Space>
        </PageContainer>
    );
};
