import { Pie } from "@ant-design/plots";
import { useTranslate } from "@pankod/refine";
import { ComponentProps, FC, useEffect, useState } from "react";
import { Grid } from "antd";
import { CampaignType } from "../../../Constanst";
import { WalletConversion } from "services";
import { Money } from "services/models/money";

interface DemoPieProps {
    type?: CampaignType | "all";
    detail: WalletConversion;
}

export const DemoPie: FC<DemoPieProps> = ({ type = "all", detail }) => {
    const [data, setData]: any = useState<any>([]);
    const [total, setTotal] = useState("");
    const t = useTranslate();
    const breakpoint = Grid.useBreakpoint();
    const isMobile = !breakpoint.lg;

    useEffect(() => {
        editData(type);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    useEffect(() => {
        let total = 0;
        // @ts-ignore
        data.forEach((d) => {
            total += d.value;
        });
        setTotal(detail.currency + total);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, detail.currency]);

    const editData = (campaignType: any) => {
        console.log("total", total);
        const install = {
            type: t("global.downloadbased") + ` : ${new Money(detail.installBalance).toStringWithCurrency(detail.currency)} `,
            value: Number(detail.installBalance),
        };
        const action = {
            type: t("global.actionbased") + ` : ${new Money(detail.actionBalance).toStringWithCurrency(detail.currency)}`,
            value: Number(detail.actionBalance),
        };
        const sell = {
            type: t("global.sellbased") + ` :${new Money(detail.sellBalance).toStringWithCurrency(detail.currency)}`,
            value: Number(detail.sellBalance),
        };
        const click = {
            type: t("global.clickbased") + ` :${new Money(detail.clickBalance).toStringWithCurrency(detail.currency)}`,
            value: Number(detail.clickBalance),
        };

        switch (campaignType) {
            case CampaignType.action:
                setData([action]);
                break;
            case CampaignType.clicking:
                setData([click]);
                break;
            case CampaignType.download:
                setData([install]);
                break;
            case CampaignType.selling:
                setData([sell]);
                break;
            default:
                setData([install, action, sell, click]);
                break;
        }
    };

    const config: ComponentProps<typeof Pie> = {
        appendPadding: 10,
        data,
        autoFit: true,
        angleField: "value",
        colorField: "type",
        color: ({ type }: any) => {
            if (type.search(t("global.downloadbased")) !== -1) {
                return "#6F2FF2";
            } else if (type.search(t("global.actionbased")) !== -1) {
                return "#D02D7E";
            } else if (type.search(t("global.sellbased")) !== -1) {
                return "#FFC720";
            } else {
                return "#98C95C";
            }
        },
        radius: 1,
        innerRadius: 0.6,
        tooltip: {
            customContent: (title: any) => {
                return `<div>${title}</div>`;
            },
        },
        label: {
            type: "inner",
            offset: "-50%",
            content: "",
            style: {
                textAlign: "center",
                fontSize: 14,
            },
        },
        interactions: [
            {
                type: "element-selected",
            },
            {
                type: "element-active",
            },
        ],
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: "pre-wrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                },
                content: "",
            },
        },
        legend: {
            position: isMobile ? "top" : "right",
            title: undefined,
        },
    };
    return (
        <>
            <Pie {...config} />
        </>
    );
};
