import { Col, Row, useTranslate } from "@pankod/refine";
import { FenBreadCrumb } from "components/BreadCrumb/BreadCrumb";
import { PageContainer } from "components/pageContainer/pageContainer";
import { FunctionComponent, useState } from "react";
import { AdminInfluencerCampaignList } from "./AdminInfluencerCampaignList";
import { AdminInfluencerGeneralInfo } from "./AdminInfluencerGeneralInfo";
import { AdminInfluencerSocialMedias } from "./AdminInfluencerSocialMedias";
import { AdminInfluencerWaitingApprovement } from "./AdminInfluencerWaitingApprovement";
import { Influencer } from "services";

interface AdminInfluencerDetailProps {
    influencer: Influencer | null;
    refresh: any;
    showEdit: boolean;
}

export const AdminInfluencerDetail: FunctionComponent<AdminInfluencerDetailProps> = (props: AdminInfluencerDetailProps) => {
    const [isEditing, setEditing] = useState(props.showEdit);
    const [isPaymentInfoEditing, setPaymentInfoEditing] = useState(false);
    const t = useTranslate();

    if (!props.influencer) return null;

    return (
        <>
            <FenBreadCrumb
                items={[
                    { name: `${t("global.phenomenons", "Fenomenler")}`, url: "/influencers/list" },
                    {
                        name: "" + props.influencer?.firstName + " " + (props.influencer?.lastName ? props.influencer?.lastName : ""),
                        url: "",
                    },
                ]}
            />
            <br />
            <Row gutter={[16, 16]}>
                {!isEditing && !isPaymentInfoEditing ? (
                    <Col xs={24} sm={24} md={12} span={12}>
                        <AdminInfluencerGeneralInfo
                            isPaymentInfoEditing={isPaymentInfoEditing}
                            setPaymentInfoEditing={setPaymentInfoEditing}
                            refresh={props.refresh}
                            isEditing={isEditing}
                            setEditing={setEditing}
                            influencer={props.influencer}
                        />
                    </Col>
                ) : (
                    <Col span={24}>
                        <AdminInfluencerGeneralInfo
                            isPaymentInfoEditing={isPaymentInfoEditing}
                            setPaymentInfoEditing={setPaymentInfoEditing}
                            refresh={props.refresh}
                            isEditing={isEditing}
                            setEditing={setEditing}
                            influencer={props.influencer}
                        />
                    </Col>
                )}

                {!isEditing && !isPaymentInfoEditing && (
                    <Col xs={24} sm={24} md={12} span={12}>
                        <PageContainer pageTitle={t("global.platforms")} helperContent={""}>
                            <AdminInfluencerWaitingApprovement refresh={props.refresh} influencer={props.influencer} />
                            <hr style={{ border: "1px solid #0000000F" }} />
                            <AdminInfluencerSocialMedias influencer={props.influencer} />
                        </PageContainer>
                    </Col>
                )}
            </Row>
            <Row gutter={[16, 16]} style={{ marginTop: 10 }}>
                <Col span={24}>{!isEditing && !isPaymentInfoEditing && <AdminInfluencerCampaignList influencer={props.influencer} />}</Col>
            </Row>
        </>
    );
};
