import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

i18n.use(Backend)
    .use(detector)
    .use(initReactI18next)
    .init({
        debug: true,
        supportedLngs: ["tr", "en", "es"],
        backend: {
            loadPath: process.env.REACT_APP_API_URL + "lookups/app-contents?culture={{lng}}",
            allowMultiLoading: false,
        },
        load: "currentOnly",
        ns: ["common"],
        defaultNS: "common",
        fallbackLng: ["tr", "en", "es"],
    });
export default i18n;
