import { Avatar, Button, Checkbox, Col, Form, Input, Row, Select, useTranslate } from "@pankod/refine";
import { PageContainer } from "components/pageContainer/pageContainer";
import { IBrand } from "modules/brands/model";
import { Fenomio_Contract_App_Brand_UpdateBrandRequest } from "services";
import { useEditForm } from "../hooks";
interface EditFormProps {
    brand: IBrand;
    updateForm: () => void;
}

export const EditForm = (props: EditFormProps) => {
    const { brand } = props;

    const [sectors, countries, editBrand] = useEditForm(props.updateForm);

    const t = useTranslate();

    return (
        <PageContainer pageTitle={"" + brand.name} helperContent={""}>
            <Form<Fenomio_Contract_App_Brand_UpdateBrandRequest>
                layout="vertical"
                requiredMark={false}
                scrollToFirstError
                onFinish={(value) => {
                    editBrand(brand.id, value);
                }}
            >
                <style>{`
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }

        input[type=number] {
            -moz-appearance:textfield; /* Firefox */
        }
      `}</style>
                <Row gutter={[30, 0]}>
                    <Col>
                        <Avatar shape="square" size={128} src={brand?.logo} style={{ marginBottom: "20px" }} />
                    </Col>
                    <Col>
                        <Col>
                            <h3 style={{ fontWeight: "bold", color: brand?.status === 3 ? "red" : "green" }}>
                                {brand?.status === 3 ? t("global.passive") : t("global.active")}
                            </h3>
                        </Col>
                        <Col style={{ padding: "50px 0px 0px 20px" }}>
                            <h2 style={{ color: "#9254de", fontWeight: "bold" }}>{brand?.name}</h2>
                            <p>
                                {brand?.sector} /{brand?.country}
                            </p>
                            <p>{brand?.recordDate}</p>
                        </Col>
                    </Col>
                </Row>
                <hr style={{ borderTop: "1px solid #952AE7" }} />
                <Row>
                    <Col xs={24} sm={24} md={6} span={6}>
                        <Row>
                            <Form.Item name="id" label="Id:" initialValue={brand?.id}>
                                <Input style={{ width: 200 }} disabled />
                            </Form.Item>
                        </Row>
                        <Row>
                            <Form.Item name="name" label={t("global.brand", "Marka")} initialValue={brand?.name}>
                                <Input style={{ width: 200 }} />
                            </Form.Item>
                        </Row>
                        <Row>
                            <Form.Item name="countryCode" label={t("global.country", "Ülke")} initialValue={brand?.countryCode}>
                                <Select
                                    style={{ width: 200 }}
                                    options={countries?.map((country) => {
                                        return {
                                            label: country.text,
                                            value: country.value,
                                        };
                                    })}
                                ></Select>
                            </Form.Item>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={6} span={6}>
                        <Row>
                            <Form.Item
                                rules={[{ required: true, message: t("pages.brand.brandRepresentative") }]}
                                name="delegateName"
                                label={t("pages.brand.brandRepresentative")}
                                initialValue={brand.delegateName}
                            >
                                <Input style={{ width: 200 }} />
                            </Form.Item>
                        </Row>
                        <Row>
                            <Form.Item name="phone" label={t("global.phone", "Telefon")} initialValue={brand?.phone}>
                                <Input style={{ width: 200 }} />
                            </Form.Item>
                        </Row>
                        <Row>
                            <Form.Item name="mail" label={t("global.email", "E-posta")} initialValue={brand?.email}>
                                <Input style={{ width: 200 }} disabled />
                            </Form.Item>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={6} span={6}>
                        <Row>
                            <Form.Item name="sector" label={t("pages.brand.sector")} initialValue={brand?.sectorId}>
                                <Select
                                    style={{ width: 200 }}
                                    allowClear
                                    options={sectors.map((sector) => {
                                        return {
                                            label: sector.name,
                                            value: sector.id,
                                        };
                                    })}
                                />
                            </Form.Item>
                        </Row>
                        <Row>
                            <Form.Item name="hasMarketingPermission" valuePropName="checked" initialValue={brand?.hasMarketingPermission}>
                                <Checkbox>
                                    <h3 style={{ fontWeight: "bold" }}>{t("pages.brand.hasMarketingPermission")}</h3>
                                </Checkbox>
                            </Form.Item>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={6} span={6}>
                        <Row>
                            <Form.Item name="detail" label={t("pages.brand.descriptionLabel")} initialValue={brand.detail}>
                                <Input.TextArea rows={4} cols={24} />
                            </Form.Item>
                        </Row>
                        <Row>
                            <Form.Item name="workingDetail" label={t("pages.brand.brandDetail")} initialValue={brand.workingDetail}>
                                <Input.TextArea rows={4} cols={24} />
                            </Form.Item>
                        </Row>
                    </Col>
                    <Row justify="end">
                        <Form.Item>
                            <Button htmlType={"submit"} type="default" style={{ borderRadius: "10px" }}>
                                {t("buttons.save")}
                            </Button>
                        </Form.Item>
                    </Row>
                </Row>
            </Form>
        </PageContainer>
    );
};
