import { InfoCircleOutlined } from "@ant-design/icons";
import { Authenticated, Col, LayoutWrapper, Row, useTranslate } from "@pankod/refine";
import { Card, Grid, Popover } from "antd";
import { FenBreadCrumb } from "components/BreadCrumb/BreadCrumb";
import { localizationHelper } from "helpers/globalizationHelper";
import { InfluencerReceipts } from "modules/profile";
import { InfluencerBalance, InfluencerConversion } from "modules/wallet";
import { IInfluencer } from "pages/campaign/Model/Influencer";
import { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { InfluencersService } from "services";

interface AdminInfluencerPerformanceProps {
    location: {
        state: {
            data: IInfluencer;
        };
    };
    match: any;
}

export const AdminInfluencerPerformance: FunctionComponent<AdminInfluencerPerformanceProps> = (props: AdminInfluencerPerformanceProps) => {
    const [influencer, setInfluencer] = useState<any>(props.location?.state?.data ?? undefined);
    const t = useTranslate();
    const breakpoint = Grid.useBreakpoint();
    const isMobile = !breakpoint.lg;

    const { influencerId } = useParams<{ influencerId: string }>();

    const fetchData = () => {
        InfluencersService.getInfluencers({
            id: influencerId,
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setInfluencer(resp?.data);
        });
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Authenticated>
            <LayoutWrapper>
                <FenBreadCrumb
                    items={[
                        { name: `${t("global.phenomenon", "Fenomen")}`, url: "/influencers/list" },
                        {
                            name: "" + (influencer as any)?.firstName + " " + (influencer as any)?.lastName,
                            url: "/influencers/detail/" + influencer?.id,
                            attachData: { data: influencer },
                        },
                        { name: t("global.performance-earning"), url: "" },
                    ]}
                />
                <br />

                <InfluencerBalance influencerId={influencerId} />
                <br />
                <InfluencerConversion influencerId={influencerId} />
                <br />
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Card id="receiptsCard">
                            <Row gutter={[8, 8]} className="ant-card-header">
                                <Col span={23}>{t("global.reciepts")}</Col>
                                <Col span={1}>
                                    <Popover
                                        content={
                                            <div>
                                                <p>Content</p>
                                                <p>Content</p>
                                            </div>
                                        }
                                        placement="bottomRight"
                                        title={t("global.reciepts")}
                                        trigger={isMobile ? "click" : "hover"}
                                    >
                                        <InfoCircleOutlined />
                                    </Popover>
                                </Col>
                            </Row>
                            <InfluencerReceipts influencerId={influencerId} />
                        </Card>
                    </Col>
                </Row>
            </LayoutWrapper>
        </Authenticated>
    );
};
