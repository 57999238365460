import { useTranslate } from "@pankod/refine";
import { Form, Row, Col, Button, Space, Grid } from "antd";
import { FC } from "react";
import { usePaymentActions } from "./usePaymentActions";
import { DownloadOutlined } from "@ant-design/icons";

type PaymentActionsProps = {
    selectedInfluencerList: any[];
    handleExport: () => void;
    payments: any[];
};

export const PaymentActions: FC<PaymentActionsProps> = ({ selectedInfluencerList, handleExport, payments }) => {
    const [showPaymentConfirmModal, handleFailedPayments] = usePaymentActions();
    const t = useTranslate();
    const breakpoint = Grid.useBreakpoint();

    const isMobile = !breakpoint.md;

    const buttonStyle = {
        marginTop: "5px",
        width: isMobile ? "100%" : "auto",
    };

    const downloadButtonStyle = {
        backgroundColor: "#BF0964",
        fontSize: 16,
        cursor: "pointer",
        width: isMobile ? "100%" : "auto",
    };

    return (
        <Form name="basic" layout="inline" initialValues={{ remember: true }} autoComplete="off">
            <Row justify="space-between" style={{ width: "100%", marginBottom: "30px", marginTop: "30px" }}>
                <Space
                    direction={isMobile ? "vertical" : "horizontal"}
                    style={{ display: "flex", width: isMobile ? "100%" : "auto", marginBottom: isMobile ? "10px" : "0" }}
                >
                    <Col xs={24} sm={24} md={5} style={{ width: isMobile ? "100%" : "auto" }}>
                        <Button
                            disabled={selectedInfluencerList.length === 0}
                            onClick={() => handleFailedPayments(selectedInfluencerList)}
                            type="default"
                            style={buttonStyle}
                        >
                            {t("buttons.paymentFailed")}
                        </Button>
                    </Col>
                    <Col xs={24} sm={24} md={5} style={{ width: isMobile ? "100%" : "auto" }}>
                        <Button
                            disabled={selectedInfluencerList.length === 0}
                            type="ghost"
                            onClick={() => {
                                showPaymentConfirmModal({ influencerList: selectedInfluencerList, payments });
                            }}
                            style={buttonStyle}
                        >
                            {t("global.payment-confirmation")}
                        </Button>
                    </Col>
                </Space>

                <Col
                    xs={24}
                    sm={24}
                    md={5}
                    style={{ width: isMobile ? "100%" : "auto", display: "flex", justifyContent: isMobile ? "center" : "flex-end" }}
                >
                    <Button style={downloadButtonStyle} type="default" onClick={() => handleExport()}>
                        {t("buttons.downloadPaymentInfo")} <DownloadOutlined style={{ fontSize: "24px" }} />
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};
