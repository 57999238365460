import { useTranslate } from "@pankod/refine";
import { Button, Col, message, Modal, Row, Typography } from "antd";
import { FC } from "react";
import { useAccountInformationModal } from "./useAccountInformationModal";
import { CopyOutlined } from "@ant-design/icons";

const { Text } = Typography;

export const PaymentAccountInformationModal: FC = () => {
    const [loading, influencerBillingDetail, open, paymentInvoiceStatus, handleClose] = useAccountInformationModal();

    const t = useTranslate();
    const handleCopyAllValues = () => {
        let allValues = "";

        if (influencerBillingDetail) {
            if (influencerBillingDetail.companyName) {
                allValues += `Company Name: ${influencerBillingDetail.companyName}\n`;
            }
            if (influencerBillingDetail.companyType) {
                allValues += `Company Type: ${influencerBillingDetail.companyType}\n`;
            }
            if (influencerBillingDetail.fullName) {
                allValues += `Full Name: ${influencerBillingDetail.fullName}\n`;
            }
            if (influencerBillingDetail.identityNumber) {
                allValues += `Identity Number: ${influencerBillingDetail.identityNumber}\n`;
            }
            if (influencerBillingDetail.address) {
                allValues += `Address: ${influencerBillingDetail.address}\n`;
            }
            if (influencerBillingDetail.bankName) {
                allValues += `Bank Name: ${influencerBillingDetail.bankName}\n`;
            }
            if (influencerBillingDetail.iban) {
                allValues += `IBAN: ${influencerBillingDetail.iban}\n`;
            }
        }

        navigator.clipboard
            .writeText(allValues)
            .then(() => {
                message.success("All values copied successfully.");
            })
            .catch((error) => {
                console.error("Copy failed:", error);
                message.error("Copying failed. Please try again.");
            });
    };
    return (
        <Modal
            open={open}
            footer={null}
            cancelText={t("global.giveup")}
            cancelButtonProps={{ type: "primary" }}
            onCancel={handleClose}
            closable={false}
            width={500}
        >
            <Row justify="space-between">
                <Col>
                    <h1 style={{ textAlign: "center", fontSize: "34px" }}>
                        <b>{t("buttons.accountInfo")}</b>
                    </h1>
                </Col>
                <Col>
                    <Button type="link" onClick={handleClose} style={{ padding: "0px" }}>
                        <img src="/images/close.svg" alt="" />
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col style={{ color: "#808080", fontSize: "16px", fontWeight: 500 }}>
                    <Text strong>
                        {paymentInvoiceStatus ? (
                            t("global.invoice")
                        ) : influencerBillingDetail?.hasExceptionLicence ? (
                            <>{t("global.bill-exception-licence")}</>
                        ) : (
                            t("global.cantinvoice")
                        )}
                    </Text>
                </Col>
            </Row>
            <hr style={{ border: "1px solid #E8E8E8", marginTop: "12px", marginBottom: "32px" }} />
            <Typography>
                {loading ? (
                    <>{t("global.loading")}</>
                ) : (
                    <>
                        {influencerBillingDetail ? (
                            <>
                                {influencerBillingDetail.isBillActive ? (
                                    <>
                                        {influencerBillingDetail.companyName && (
                                            <>
                                                <div style={{ display: "flex", justifyContent: "space-between", fontSize: "16px" }}>
                                                    <strong>{t("global.company-name")}</strong>
                                                    <p> {influencerBillingDetail.companyName}</p>
                                                </div>

                                                <hr style={{ border: "1px solid #E8E8E8", marginTop: "16px", marginBottom: "16px" }} />
                                            </>
                                        )}
                                        {influencerBillingDetail.companyType && (
                                            <>
                                                <div style={{ display: "flex", justifyContent: "space-between", fontSize: "16px" }}>
                                                    <strong>{t("global.company-type")}</strong>
                                                    <p>{t("global.company-type-" + influencerBillingDetail.companyType.toString())}</p>
                                                </div>

                                                <hr style={{ border: "1px solid #E8E8E8", marginTop: "16px", marginBottom: "16px" }} />
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {influencerBillingDetail.fullName && (
                                            <>
                                                <div style={{ display: "flex", justifyContent: "space-between", fontSize: "16px" }}>
                                                    <strong>{t("global.full-name")}</strong>
                                                    <p> {influencerBillingDetail.fullName}</p>
                                                </div>

                                                <hr style={{ border: "1px solid #E8E8E8", marginTop: "16px", marginBottom: "16px" }} />
                                            </>
                                        )}
                                        {influencerBillingDetail.identityNumber && (
                                            <>
                                                <div style={{ display: "flex", justifyContent: "space-between", fontSize: "16px" }}>
                                                    <strong>{t("global.identity-number")}</strong>
                                                    <p>{influencerBillingDetail.identityNumber}</p>
                                                </div>

                                                <hr style={{ border: "1px solid #E8E8E8", marginTop: "16px", marginBottom: "16px" }} />
                                            </>
                                        )}
                                        {influencerBillingDetail.address && (
                                            <>
                                                <div style={{ display: "flex", justifyContent: "space-between", fontSize: "16px" }}>
                                                    <strong>{t("global.address")}</strong>
                                                    <p style={{ width: "45%", textAlign: "end" }}>{influencerBillingDetail.address}</p>
                                                </div>

                                                <hr style={{ border: "1px solid #E8E8E8", marginTop: "16px", marginBottom: "16px" }} />
                                            </>
                                        )}
                                    </>
                                )}
                                {influencerBillingDetail.bankName && (
                                    <>
                                        <div style={{ display: "flex", justifyContent: "space-between", fontSize: "16px" }}>
                                            <strong>{t("global.bank-name")}</strong>
                                            <p>{influencerBillingDetail.bankName}</p>
                                        </div>

                                        <hr style={{ border: "1px solid #E8E8E8", marginTop: "16px", marginBottom: "16px" }} />
                                    </>
                                )}
                                {influencerBillingDetail.iban && (
                                    <>
                                        <div style={{ display: "flex", justifyContent: "space-between", fontSize: "16px" }}>
                                            <strong>{t("global.iban")}</strong>
                                            <p>{influencerBillingDetail.iban}</p>
                                        </div>

                                        <hr style={{ border: "1px solid #E8E8E8", marginTop: "16px", marginBottom: "16px" }} />
                                    </>
                                )}
                            </>
                        ) : (
                            <>{t("global.error")}</>
                        )}
                    </>
                )}
            </Typography>
            <Row justify="start">
                <Col
                    onClick={handleCopyAllValues}
                    style={{ textAlign: "start", color: "#952AE7", fontSize: "16px", fontWeight: 600, cursor: "pointer" }}
                >
                    <CopyOutlined /> Copy
                </Col>
            </Row>
        </Modal>
    );
};
