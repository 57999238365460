import { Space, Button, Table, Authenticated, LayoutWrapper, useTranslate, Tooltip } from "@pankod/refine";
import { PageContainer } from "components/pageContainer/pageContainer";
import { PaginationConstant } from "Constanst";
import { FunctionComponent, useEffect, useState } from "react";
import { InfluencersService } from "../../services";
import { Pagination } from "../../services/core/Pagination";
import { localizationHelper } from "../../helpers/globalizationHelper";
import { AlignType } from "rc-table/lib/interface";
import { Link } from "react-router-dom";

interface AmazonTrackingNumberProps {}

export const AmazonTrackingNumber: FunctionComponent<AmazonTrackingNumberProps> = () => {
    const t = useTranslate();
    const [pagination, setPagination] = useState<Pagination>({
        current: PaginationConstant.initialPage,
        pageSize: PaginationConstant.initialPageSize,
    });
    //const [filterValues] = useState<any>();

    const [dataLoading, setDataLoading] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [copyText, setCopySet] = useState<string>("Copy");

    const [search, setSearch] = useState<boolean>(true);

    useEffect(() => {
        if (search) fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const fetchData = () => {
        setDataLoading(true);
        InfluencersService.searchInfluencers({
            body: prepareFilterRequest(),
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setDataLoading(false);
            setData(resp?.data?.data);
            setPagination({
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: resp?.data?.pageInfo.totalItemCount,
            });
            setSearch(false);
        });
    };

    const prepareFilterRequest = () => {
        let body = {
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            amazonTrackingApprove: true,
        };
        return body;
    };

    const handleTableChange = (page: any) => {
        setPagination({
            current: page.current,
            pageSize: page.pageSize,
            total: page.total,
        });
        setSearch(true);
    };

    const approve = (influencerId: any) => {
        InfluencersService.amazonTrackingCodeApprove({ id: influencerId, acceptLanguage: localizationHelper.getLocale() })
            .then((response) => fetchData())

            .catch((error) => {
                console.error(error);
            });
    };

    const copyTrackingCode = (text: any) => {
        if (text.includes("-21")) {
            navigator.clipboard.writeText(text.substring(0, text.length - 3));
        } else {
            navigator.clipboard.writeText(text);
        }

        setCopySet("Copied !");

        setTimeout(() => {
            setCopySet("Copy");
        }, 2000);
    };

    const columns = [
        {
            title: t("global.namee"),
            dataIndex: "firstName",
            key: "firstName",
            align: "center" as AlignType,
            render: (text: string, row: any, index: number) => (
                <>
                    <Space direction="vertical" size="small">
                        {
                            <Link to={"/influencers/detail/" + row.id}>
                                <Button type="link" style={{ fontWeight: "bold", padding: 0, borderWidth: 0 }}>
                                    {" "}
                                    {row.firstName} {row.lastName}
                                </Button>
                            </Link>
                        }
                        {row.socialMedias.map((social: any) => (
                            <a
                                href={social.link}
                                target="_blank"
                                rel="noreferrer"
                                style={{ padding: 0, height: 25, fontSize: "small", color: "#9254de" }}
                            >
                                <img
                                    alt="social media icon"
                                    width={22}
                                    height={22}
                                    src={`/images/social-media-icon/${social?.name?.toUpperCase()}36Px.svg`}
                                />{" "}
                                {"@" + social.username}{" "}
                            </a>
                        ))}
                    </Space>
                </>
            ),
        },
        {
            title: t("global.tracking-no"),
            dataIndex: "amazonTrackingCode",
            key: "amazonTrackingCode",
            align: "center" as AlignType,
            render: (text: string, row: any, index: number) => (
                <>
                    <Tooltip placement="bottom" title={copyText}>
                        {text}
                        <img
                            alt="copy"
                            src="/images/menu-icon/COPY.svg"
                            style={{ cursor: "pointer" }}
                            onClick={() => copyTrackingCode(text)}
                        />
                    </Tooltip>
                </>
            ),
        },
        {
            title: `${t("global.action", "Aksiyon")}`,
            dataIndex: "action",
            key: "action",
            align: "center" as AlignType,
            render: (text: string, row: any, index: number) => (
                <>
                    <Button onClick={() => approve(row.id)}>{t("global.accept", "Kabul Et")}</Button>
                </>
            ),
        },
    ];

    return (
        <Authenticated>
            <LayoutWrapper>
                <PageContainer pageTitle={t("global.amazon-tracking-no")} helperContent="">
                    <Table
                        columns={columns}
                        dataSource={data}
                        scroll={{ x: "100%" }}
                        pagination={pagination}
                        loading={dataLoading}
                        onChange={handleTableChange}
                    />
                </PageContainer>
            </LayoutWrapper>
        </Authenticated>
    );
};
