import React, { useState } from "react";
import { message, Upload, Modal, Button, Spin } from "@pankod/refine";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { BrandsService, InfluencersService } from "../../../services";
import { localizationHelper } from "../../../helpers/globalizationHelper";
import UserHelper from "../../../helpers/userHelper";
import { Roles } from "../../../helpers/roleHelper";
import { storageHelper } from "../../../helpers/storageHelper";
import * as Constants from "../../../Constanst";

const Avatar = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [crop, setCrop] = useState<Crop>({
        unit: "%",
        width: 30,
        x: 0,
        y: 0,
        height: 30,
    });
    const [showCropModal, setShowCropModal] = useState(false);
    const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);
    const userRole = UserHelper.getRole();

    const onImageLoaded = (image: HTMLImageElement) => {
        setImageRef(image);
    };

    const onCropComplete = async (): Promise<void> => {
        if (imageRef && crop.width && crop.height) {
            const canvas = document.createElement("canvas");
            const scaleX = imageRef.naturalWidth / imageRef.width;
            const scaleY = imageRef.naturalHeight / imageRef.height;
            canvas.width = crop.width!;
            canvas.height = crop.height!;
            const ctx = canvas.getContext("2d");

            if (ctx) {
                ctx.drawImage(
                    imageRef,
                    crop.x! * scaleX,
                    crop.y! * scaleY,
                    crop.width! * scaleX,
                    crop.height! * scaleY,
                    0,
                    0,
                    crop.width!,
                    crop.height!
                );
                const base64Image = canvas.toDataURL("image/jpeg");
                await updatePhoto(base64Image);
            } else {
                console.error("Canvas context not available.");
            }
        } else {
            console.error("Image reference or crop dimensions not available.");
        }
    };

    const beforeUpload = (file: any) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("You can only upload JPG/PNG file!");
            console.error("Invalid file type:", file.type);
            return false;
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("Image must be smaller than 2MB!");
            console.error("File size exceeds 2MB:", file.size);
            return false;
        }
        return true;
    };

    const handleChange = (info: any) => {
        if (info.file.status === "done") {
            setShowCropModal(true);
            const reader = new FileReader();
            reader.onload = () => {
                setImageUrl(reader.result as string);
            };
            reader.onerror = (error) => {
                console.error("Error reading file:", error);
            };
            reader.readAsDataURL(info.file.originFileObj);
        }
    };

    const updatePhoto = async (croppedImage: string): Promise<boolean> => {
        const fmData = new FormData();
        const blob = dataURLtoBlob(croppedImage);

        if (blob) {
            fmData.append("file", blob, "avatar.jpg");

            setLoading(true);
            try {
                let response;
                if (userRole === Roles.Influencer) {
                    response = await InfluencersService.uploadPhoto({ body: fmData, acceptLanguage: localizationHelper.getLocale() });
                } else if (userRole === Roles.Brand) {
                    response = await BrandsService.uploadPhoto({ body: fmData, acceptLanguage: localizationHelper.getLocale() });
                }
                storageHelper.setItem(Constants.UPDATED_PHOTO, response.data);
                setImageUrl(response.data);
                return true;
            } catch (error) {
                console.error("Error uploading photo:", error);
                return false;
            } finally {
                setLoading(false);
            }
        } else {
            console.error("Failed to convert cropped image to Blob.");
            return false;
        }
    };

    const handleOk = async () => {
        try {
            await onCropComplete();
            setShowCropModal(false);
        } catch (error) {
            console.error("Error during crop or upload:", error);
        }
    };

    return (
        <div>
            <Upload
                name="avatar"
                listType="picture-card"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                customRequest={async ({ onSuccess }) => {
                    if (onSuccess) onSuccess("ok");
                }}
            >
                {UserHelper.getLogoPath() ? (
                    <div>
                        <img src={UserHelper.getLogoPath()} alt="avatar" style={{ width: "100%" }} />
                    </div>
                ) : (
                    <></>
                )}
            </Upload>

            {showCropModal && (
                <Modal
                    open={showCropModal}
                    onCancel={() => setShowCropModal(false)}
                    onOk={handleOk}
                    title="Crop your image"
                    footer={[
                        <Button key="back" onClick={() => setShowCropModal(false)}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                            {loading ? <Spin size="small" /> : "Crop & Upload"}
                        </Button>,
                    ]}
                >
                    {loading ? (
                        <div style={{ textAlign: "center", padding: "20px" }}>
                            <Spin size="large" />
                            <p>Processing image...</p>
                        </div>
                    ) : (
                        imageUrl && (
                            <ReactCrop crop={crop} onChange={(newCrop) => setCrop(newCrop)} aspect={1}>
                                <img src={imageUrl} alt="Crop" onLoad={(e) => onImageLoaded(e.currentTarget)} />
                            </ReactCrop>
                        )
                    )}
                </Modal>
            )}
        </div>
    );
};

export default Avatar;

function dataURLtoBlob(dataURL: string) {
    if (!dataURL) return null;
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}
