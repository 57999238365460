// @flow
import * as React from "react";
import { Col, Popover, Row, Grid } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

type Props = {
    title: string;
    content: React.ReactNode;
};

const PopoverTrigger = ({ title, content }: Props) => {
    const breakpoint = Grid.useBreakpoint();
    const isMobile = !breakpoint.lg;

    return (
        <Popover content={content} placement="bottomRight" title={title} trigger={isMobile ? "click" : "hover"}>
            <InfoCircleOutlined />
        </Popover>
    );
};

export class PageTitle extends React.Component<Props> {
    render() {
        return (
            <Row gutter={[8, 8]} className="ant-card-header">
                <Col span={23}>{this.props.title}</Col>
                <Col span={1}>
                    <PopoverTrigger title={this.props.title} content={this.props.content} />
                </Col>
            </Row>
        );
    }
}
