import { Button, useTranslate, useGetLocale, useSetLocale } from "@pankod/refine";
import { Dropdown, Avatar, MenuProps, Grid } from "antd";
import { BellFilled } from "@ant-design/icons";
import { NotificationPopup } from "./NotificationPopup";
import UserHelper from "../../helpers/userHelper";
import { useEffect, useState } from "react";
import { NotificationService } from "../../services/services/NotificationService";
import { localizationHelper } from "../../helpers/globalizationHelper";
import ProfileImg from "components/microparts/profile-img";
import { Popover } from "antd";
import { useTranslation } from "react-i18next";

import "./header.scss";

export const Header: React.FC = () => {
    const role = UserHelper.getRole();
    const name = UserHelper.getName();
    const t = useTranslate();
    const { i18n } = useTranslation();
    const locale = useGetLocale();
    const changeLanguage = useSetLocale();
    const [count, setCount] = useState<any>();
    const currentLocale = locale();

    const menuItems: MenuProps["items"] = [...(i18n.languages || [])].sort().map((lang: string) => ({
        key: lang,
        onClick: () => changeLanguage(lang).then(() => window.location.reload()),
        icon: (
            <span style={{ marginRight: 8 }}>
                <Avatar size={16} src={`/images/flags/${lang}.svg`} />
            </span>
        ),
        label: lang === "tr" ? "Türkçe" : lang === "es" ? "Español" : "English",
    }));

    const breakpoint = Grid.useBreakpoint();
    const isMobile = !breakpoint.lg;

    useEffect(() => {
        fetchCount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchCount = () => {
        NotificationService.InformationCount({
            acceptLanguage: localizationHelper.getLocale(),
        })
            .then((response) => {
                setCount(response);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <>
            <header className="header">
                <div className="header-inner">
                    <a href="/" className={isMobile ? "logo-mobile" : "logo"}>
                        <img src="/images/logo/logo.png" alt="logo" className={isMobile ? "logo-img-mobile" : "Logo"} />
                    </a>

                    <div
                        onClick={() => {
                            fetchCount();
                        }}
                        className="auth-user"
                    >
                        <Popover placement="bottomRight" content={<NotificationPopup fetchCount={fetchCount} />} trigger="click">
                            <div className="notification">
                                <Button>
                                    {count > 0 && <span className="size">{count}</span>}

                                    <BellFilled />
                                </Button>
                            </div>
                        </Popover>
                        {!isMobile && (
                            <div className="user-info">
                                {!isMobile && <ProfileImg alt={t("global.profile")} type={role} negative />}
                                {/* <img width={56} height={56} src="https://via.placeholder.com/728x90.png" alt="" /> */}
                                {!isMobile && (
                                    <div>
                                        <div className="name"> {name}</div>
                                    </div>
                                )}
                            </div>
                        )}

                        <Dropdown
                            menu={{
                                items: menuItems,
                                selectedKeys: currentLocale ? [currentLocale] : [],
                            }}
                            placement="bottomLeft"
                            className="dropdown-lang"
                        >
                            <Button type="link" shape="circle">
                                <Avatar size={24} src={`/images/flags/${currentLocale}.svg`} />
                            </Button>
                        </Dropdown>
                    </div>
                </div>
            </header>
        </>
    );
};
