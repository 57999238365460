import { useTranslate } from "@pankod/refine";
import { EVENTS } from "Constanst";
import { Button, Col, Modal, Row } from "antd";
import { useModal } from "hooks";
import { FC } from "react";

export const PaymentListModal: FC = () => {
    const [open, paymentList, handleClose] = useModal<any[]>(EVENTS.PAYMENT_LIST_MODAL_IS_OPENED);
    const t = useTranslate();
    if (!paymentList) return null;
    return (
        <Modal
            open={open}
            footer={null}
            cancelText={t("global.giveup")}
            cancelButtonProps={{ type: "primary" }}
            onCancel={handleClose}
            closable={false}
            width={430}
        >
            <Row justify="space-between">
                <Col>
                    <h1 style={{ textAlign: "center", fontSize: "34px" }}>
                        <b>{t("global.past-payment")}</b>
                    </h1>
                </Col>
                <Col>
                    <Button type="link" onClick={handleClose} style={{ padding: "0px" }}>
                        <img src="/images/close.svg" alt="" />
                    </Button>
                </Col>
            </Row>
            <hr style={{ border: "1px solid #E8E8E8", marginTop: "1px", marginBottom: "32px" }} />
            <Row justify="space-between">
                <Col>
                    <strong>{t("global.date")}</strong>
                </Col>
                <Col>
                    <strong>{t("global.amount")}</strong>
                </Col>
            </Row>
            <hr style={{ border: "1px solid #E8E8E8", marginTop: "5px", marginBottom: "16px" }} />

            <>
                {paymentList.map((payment: any) => (
                    <>
                        <Row justify="space-between">
                            <Col>{payment.date}</Col>
                            <Col>{payment.amount}</Col>
                        </Row>
                        <hr style={{ border: "1px solid #E8E8E8", marginTop: "5px", marginBottom: "16px" }} />
                    </>
                ))}
            </>
        </Modal>
    );
};
