import { Badge, Space, Tabs, useTranslate } from "@pankod/refine";
import { PageContainer } from "components/pageContainer/pageContainer";
import { localizationHelper } from "helpers/globalizationHelper";
import { FunctionComponent, useEffect, useState } from "react";
import { CampaignInfluencerStates, CampaignStates, PaginationConstant } from "../../../../../Constanst";
import { CampaignsService } from "../../../../../services";
import { Pagination } from "../../../../../services/core/Pagination";
import "./admin-influencer.css";
import { CampaignList } from "./CampaignList";

const { TabPane } = Tabs;

export enum CampaignListType {
    applied,
    active,
    rejected,
    invited,
    past,
}

interface AdminInfluencerCampaignListProps {
    influencer: any;
}

export const AdminInfluencerCampaignList: FunctionComponent<AdminInfluencerCampaignListProps> = (
    props: AdminInfluencerCampaignListProps
) => {
    const [countData, setCountData] = useState<any>({
        activeCount: 0,
        invitedCount: 0,
        appliedCount: 0,
        rejectedCount: 0,
        pastCount: 0,
    });

    const [pagination, setPagination] = useState<Pagination>({
        current: PaginationConstant.initialPage,
        pageSize: PaginationConstant.initialPageSize,
    });

    const [activeTab, setActiveTab] = useState(1);
    const [campaigns, setCampaigns] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState<boolean>(true);

    const t = useTranslate();

    useEffect(() => {
        if (props.influencer) fetchInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.influencer]);

    useEffect(() => {
        if (props.influencer && search) {
            setSearch(false);
            getCampaigns();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.influencer, search]);

    const getCampaigns = () => {
        setLoading(true);

        let campaignInfStates: any[] = [];
        let campaignStates: any[] = [];

        var includeBothStates = false;

        if (+activeTab === 1) {
            // accepted
            CampaignsService.getCampaignInfluencersActive({
                body: {
                    influencerId: props.influencer.id,
                    pageNumber: pagination.current,
                    pageSize: pagination.pageSize,
                },
                acceptLanguage: localizationHelper.getLocale(),
            }).then((resp) => {
                setCampaigns(resp.data?.data);
                setPagination({
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: resp?.data?.pageInfo.totalItemCount,
                });
                setLoading(false);
                setSearch(false);
            });
        } else if (+activeTab === 2) {
            // invited
            CampaignsService.getCampaignInfluencersInvited({
                body: {
                    influencerId: props.influencer.id,
                    pageNumber: pagination.current,
                    pageSize: pagination.pageSize,
                },
                acceptLanguage: localizationHelper.getLocale(),
            }).then((resp) => {
                setCampaigns(resp.data?.data);
                setPagination({
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: resp?.data?.pageInfo.totalItemCount,
                });
                setLoading(false);
                setSearch(false);
            });
        } else if (+activeTab === 3) {
            // applied
            CampaignsService.getCampaignInfluencersApplied({
                body: {
                    influencerId: props.influencer.id,
                    pageNumber: pagination.current,
                    pageSize: pagination.pageSize,
                },
                acceptLanguage: localizationHelper.getLocale(),
            }).then((resp) => {
                setCampaigns(resp.data?.data);
                setPagination({
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: resp?.data?.pageInfo.totalItemCount,
                });
                setLoading(false);
                setSearch(false);
            });
        } else if (+activeTab === 4) {
            // rejected
            campaignInfStates = [CampaignInfluencerStates.Rejected];
            campaignStates = [CampaignStates.Accepted];
            includeBothStates = true;

            CampaignsService.getCampaignInfluencers({
                body: {
                    influencerId: props.influencer.id,
                    states: campaignInfStates,
                    campaingStates: campaignStates,
                    pageNumber: pagination.current,
                    pageSize: pagination.pageSize,
                    includeBothStates: includeBothStates,
                },
                acceptLanguage: localizationHelper.getLocale(),
            }).then((resp) => {
                setCampaigns(resp.data?.data);
                setPagination({
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: resp?.data?.pageInfo.totalItemCount,
                });
                setLoading(false);
                setSearch(false);
            });
        } else if (+activeTab === 5) {
            // past campaigns
            CampaignsService.getCampaignInfluencersPast({
                body: {
                    influencerId: props.influencer.id,
                    pageNumber: pagination.current,
                    pageSize: pagination.pageSize,
                },
                acceptLanguage: localizationHelper.getLocale(),
            }).then((resp) => {
                setCampaigns(resp.data?.data);
                setPagination({
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: resp?.data?.pageInfo.totalItemCount,
                });
                setLoading(false);
                setSearch(false);
            });
        }
    };

    const handleTableChange = (page: any) => {
        setPagination({
            current: page.current,
            pageSize: page.pageSize,
            total: page.total,
        });
        setSearch(true);
    };

    const activeTabChanged = (tab: any) => {
        setActiveTab(tab);
        setPagination({
            current: PaginationConstant.initialPage,
            pageSize: PaginationConstant.initialPageSize,
        });
        setSearch(true);
    };

    const fetchInfo = () => {
        CampaignsService.getCampaignInfluencersInfoByInfluencer({
            influencerId: props.influencer?.id,
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setCountData(resp.data);
            setActiveTab(1);
            //setSearch(true);
        });
    };

    const refresh = () => {
        fetchInfo();
        getCampaigns();
    };

    return (
        <PageContainer
            pageTitle={props.influencer?.firstName + " " + (props.influencer?.lastName ? props.influencer?.lastName : "")}
            helperContent={""}
        >
            <Tabs onChange={(tab) => activeTabChanged(tab)} activeKey={activeTab.toString()}>
                <TabPane
                    tab={
                        <Space direction="horizontal">
                            <span style={{ fontSize: "small" }}>{t("campaign.create.step1.activecampaign")}</span>
                            <Badge count={countData?.activeCount} />
                        </Space>
                    }
                    disabled={countData?.activeCount === 0}
                    key="1"
                >
                    <CampaignList
                        refresh={refresh}
                        pagination={pagination}
                        pageChanged={handleTableChange}
                        loading={loading}
                        listType={CampaignListType.active}
                        data={campaigns}
                    />
                </TabPane>
                <TabPane
                    tab={
                        <Space direction="horizontal">
                            <span style={{ fontSize: "small" }}>{t("campaign.create.step1.invited-campaigns")}</span>
                            <Badge count={countData?.invitedCount} />
                        </Space>
                    }
                    disabled={countData?.invitedCount === 0}
                    key="2"
                >
                    <CampaignList
                        refresh={refresh}
                        pagination={pagination}
                        pageChanged={handleTableChange}
                        loading={loading}
                        listType={CampaignListType.invited}
                        data={campaigns}
                    />
                </TabPane>
                <TabPane
                    tab={
                        <Space direction="horizontal">
                            <span style={{ fontSize: "small" }}>{t("campaign.create.step1.applied-campaigns")}</span>
                            <Badge count={countData?.appliedCount} />
                        </Space>
                    }
                    disabled={countData?.appliedCount === 0}
                    key="3"
                >
                    <CampaignList
                        refresh={refresh}
                        pagination={pagination}
                        pageChanged={handleTableChange}
                        loading={loading}
                        listType={CampaignListType.applied}
                        data={campaigns}
                    />
                </TabPane>
                <TabPane
                    tab={
                        <Space direction="horizontal">
                            <span style={{ fontSize: "small" }}>{t("campaign.create.step1.rejected-campaigns")}</span>
                            <Badge count={countData?.rejectedCount} />
                        </Space>
                    }
                    disabled={countData?.rejectedCount === 0}
                    key="4"
                >
                    <CampaignList
                        refresh={refresh}
                        pagination={pagination}
                        pageChanged={handleTableChange}
                        loading={loading}
                        listType={CampaignListType.rejected}
                        data={campaigns}
                    />
                </TabPane>
                <TabPane
                    tab={
                        <Space direction="horizontal">
                            <span style={{ fontSize: "small" }}>{t("campaign.create.step2.pastCampaignss")}</span>
                            <Badge count={countData?.pastCount} />
                        </Space>
                    }
                    disabled={countData?.pastCount === 0}
                    key="5"
                >
                    <CampaignList
                        refresh={refresh}
                        pagination={pagination}
                        pageChanged={handleTableChange}
                        loading={loading}
                        listType={CampaignListType.past}
                        data={campaigns}
                    />
                </TabPane>
            </Tabs>
        </PageContainer>
    );
};
