import { useTranslate } from "@pankod/refine";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { FC } from "react";
import { usePaymentRefundOrBonus } from "./usePaymentRefundOrBonus";

export interface PaymentRefundOrBonusDetail {
    id: string;
    type: "refund" | "bonus";
}
export const PaymentRefundOrBonusModal: FC = () => {
    const [open, data, handleClose, campaigns, handleSubmit, loading] = usePaymentRefundOrBonus<PaymentRefundOrBonusDetail>();
    const t = useTranslate();

    const { TextArea } = Input;

    if (!open) return null;
    return (
        <Modal
            footer={null}
            open={open}
            cancelText={t("global.giveup")}
            cancelButtonProps={{ type: "primary" }}
            onCancel={handleClose}
            closable={false}
            width={450}
        >
            <Row justify="space-between">
                <Col>
                    <h1 style={{ textAlign: "center", fontSize: "34px" }}>
                        <b>{data?.type === "refund" ? t("global.addReturn") : t("campaign.create.step3.addbonus")}</b>
                    </h1>
                </Col>
                <Col>
                    <Button type="link" onClick={handleClose} style={{ padding: "0px" }}>
                        <img src="/images/close.svg" alt="close img" />
                    </Button>
                </Col>
            </Row>
            <hr style={{ border: "1px solid #E8E8E8", marginTop: "1px", marginBottom: "32px" }} />

            <Form
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                onFinish={(values) => {
                    handleSubmit(values, data?.type);
                }}
                onFinishFailed={() => {}}
                autoComplete="off"
            >
                <Form.Item
                    label={<span style={{ fontSize: "20px", color: "#91979D", fontWeight: 600 }}>{t("global.campaign")}</span>}
                    name="campaignId"
                >
                    <Select
                        options={campaigns.map((c) => {
                            return {
                                label: c.campaignName,
                                value: c.campaignId,
                            };
                        })}
                    />
                </Form.Item>
                <Form.Item
                    label={<span style={{ fontSize: "20px", color: "#91979D", fontWeight: 600 }}>{t("campaign.create.step3.amount")}</span>}
                    name="amount"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={<span style={{ fontSize: "20px", color: "#91979D", fontWeight: 600 }}>{t("global.description")}</span>}
                    name="description"
                    rules={[{ max: 270, message: t("global.payment.modal.description.text-max-length") }]}
                >
                    <TextArea rows={4} />
                </Form.Item>

                <Form.Item wrapperCol={{ span: 24 }}>
                    {" "}
                    <Button type="default" htmlType="submit" loading={loading} style={{ width: "100%" }}>
                        {t("global.add")}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};
