import { useTranslate } from "@pankod/refine";
import { Modal, Typography } from "antd";
import { FC } from "react";
import { usePaymentApprovement } from "./usePaymentApprovement";
import { Money } from "services/models/money";

const { Paragraph, Title } = Typography;

export interface PaymentApprovementDetail {
    id: string;
    firstName: string;
    lastName: string;
    paymentTotalBalanceOnMonth: number;
    currency: string;
}

export const PaymentApprovementModal: FC = () => {
    const [open, detail, handleClose, handleOk] = usePaymentApprovement<PaymentApprovementDetail>();

    const t = useTranslate();

    if (!detail) return null;

    const { id, firstName, lastName, paymentTotalBalanceOnMonth, currency } = detail;
    return (
        <Modal
            open={open}
            title={<Title level={4}>{t("payment.confirmation-payment")}</Title>}
            okText={t("global.accept")}
            okType={"default"}
            cancelText={t("global.giveup")}
            cancelButtonProps={{ type: "primary" }}
            onCancel={handleClose}
            onOk={() => handleOk(id)}
        >
            <Typography>
                <Paragraph style={{ fontSize: 16 }}>
                    {firstName} {lastName}’e {new Money(paymentTotalBalanceOnMonth).toStringWithCurrency(currency)} Ödeme Yapmayı onaylıyor
                    musun?
                </Paragraph>
            </Typography>
        </Modal>
    );
};
