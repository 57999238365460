import { CopyOutlined } from "@ant-design/icons";
import { useTranslate } from "@pankod/refine";
import { EVENTS } from "Constanst";
import { Button, Col, message, Modal, Row } from "antd";
import { useModal } from "hooks";
import { FC } from "react";
import { Money } from "services/models/money";

export const PaymentBalanceDetailModal: FC = () => {
    // TODO: paymentBalanceOnMonthDetails model will be added
    const [open, detail, handleClose] = useModal<any>(EVENTS.PAYMENT_BALANCE_DETAIL_MODAL_IS_OPENED);
    const t = useTranslate();

    if (!detail) return null;

    const { paymentBalanceOnMonthDetails } = detail;
    const handleCopyAllValues = () => {
        const allValues = `
            Hak Ediş: ${new Money(paymentBalanceOnMonthDetails?.progressPaymentLastMonth).toStringWithCurrency(
                paymentBalanceOnMonthDetails?.currency
            )}
            Stopaj Tutarı (%15): ${new Money(paymentBalanceOnMonthDetails?.withholdingAmount).toStringWithCurrency(
                paymentBalanceOnMonthDetails?.currency
            )}
            Net Tutar: ${new Money(paymentBalanceOnMonthDetails?.netAmount).toStringWithCurrency(paymentBalanceOnMonthDetails?.currency)}
            KDV (%20): ${new Money(paymentBalanceOnMonthDetails?.vat).toStringWithCurrency(paymentBalanceOnMonthDetails?.currency)}
            KDV Tevkifat: ${new Money(paymentBalanceOnMonthDetails?.vatWithholding).toStringWithCurrency(
                paymentBalanceOnMonthDetails?.currency
            )}
            ÖDENECEK NET: ${new Money(paymentBalanceOnMonthDetails?.netPayable).toStringWithCurrency(
                paymentBalanceOnMonthDetails?.currency
            )}
        `;

        navigator.clipboard
            .writeText(allValues)
            .then(() => {
                message.success("All values copied successfully.");
            })
            .catch((error) => {
                console.error("Copy failed:", error);
                message.error("Copying failed. Please try again.");
            });
    };


    return (
        <Modal
            open={open}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            onCancel={handleClose}
            closable={false}
            width={490}
            footer={null}
        >
            <Row justify="space-between">
                <Col>
                    <h1 style={{ textAlign: "center", fontSize: "34px" }}>
                        <b>{t("campaign.create.step3.netamountpayable")}</b>
                    </h1>
                </Col>
                <Col>
                    <Button type="link" onClick={handleClose} style={{ padding: "0px" }}>
                        <img src="/images/close.svg" alt="close icon" />
                    </Button>
                </Col>
            </Row>
            <Row>

                <Col style={{ color: "#808080", fontSize: "16px", fontWeight: 500 }}>
                    *KDV dahil toplam tutar 6900₺ ve üzerine tevkifat uygulanır.
                </Col>
            </Row>
            <hr style={{ border: "1px solid #E8E8E8", marginTop: "12px", marginBottom: "32px" }} />

            <Row style={{ fontSize: "16px" }} justify="space-between">
                <Col span={9} style={{ fontWeight: 600 }}>
                    {t("campaign.create.step3.deserve")}
                </Col>
                <Col span={9} style={{ textAlign: "right" }}>
                    {new Money(paymentBalanceOnMonthDetails?.progressPaymentLastMonth).toStringWithCurrency(
                        paymentBalanceOnMonthDetails?.currency
                    )}
                </Col>
            </Row>
            <hr style={{ border: "1px solid #E8E8E8", marginTop: "16px", marginBottom: "16px" }} />

            <Row style={{ fontSize: "16px" }} justify="space-between">
                <Col span={9} style={{ fontWeight: 600 }}>
                    {t("reports.payment.paymentAccountDetails.modal.field.withholdingAmount")}
                </Col>
                <Col span={9} style={{ textAlign: "right" }}>
                    {new Money(paymentBalanceOnMonthDetails?.withholdingAmount).toStringWithCurrency(
                        paymentBalanceOnMonthDetails?.currency
                    )}
                </Col>
            </Row>
            <hr style={{ border: "1px solid #E8E8E8", marginTop: "16px", marginBottom: "16px" }} />

            <Row style={{ fontSize: "16px" }} justify="space-between">
                <Col span={9} style={{ fontWeight: 600 }}>
                    {t("campaign.create.step3.netamount")}
                </Col>
                <Col span={9} style={{ textAlign: "right" }}>
                    {new Money(paymentBalanceOnMonthDetails?.netAmount).toStringWithCurrency(paymentBalanceOnMonthDetails?.currency)}
                </Col>
            </Row>
            <hr style={{ border: "1px solid #E8E8E8", marginTop: "16px", marginBottom: "16px" }} />

            <Row style={{ fontSize: "16px" }} justify="space-between">
                <Col span={9} style={{ fontWeight: 600 }}>
                    {t("campaign.create.step3.kdv")} (%20)
                </Col>
                <Col span={9} style={{ textAlign: "right" }}>
                    {new Money(paymentBalanceOnMonthDetails?.vat).toStringWithCurrency(paymentBalanceOnMonthDetails?.currency)}
                </Col>
            </Row>
            <hr style={{ border: "1px solid #E8E8E8", marginTop: "16px", marginBottom: "16px" }} />

            <Row style={{ fontSize: "16px" }} justify="space-between">
                <Col span={9} style={{ fontWeight: 600 }}>
                    {t("campaign.create.step3.kdvtevkifat")}
                </Col>
                <Col span={9} style={{ textAlign: "right" }}>
                    {new Money(paymentBalanceOnMonthDetails?.vatWithholding).toStringWithCurrency(paymentBalanceOnMonthDetails?.currency)}
                </Col>
            </Row>
            <hr style={{ border: "1px solid #E8E8E8", marginTop: "16px", marginBottom: "16px" }} />

            <Row style={{ fontSize: "20px", fontWeight: 700 }} justify="space-between">
                <Col span={9}> {t("campaign.create.step3.netpayable")}</Col>
                <Col span={9} style={{ textAlign: "right" }}>
                    {new Money(paymentBalanceOnMonthDetails?.netPayable).toStringWithCurrency(paymentBalanceOnMonthDetails?.currency)}
                </Col>
            </Row>
            <hr style={{ border: "1px solid #E8E8E8", marginTop: "16px", marginBottom: "16px" }} />
            <Row justify="start">
                <Col
                    onClick={handleCopyAllValues}
                    style={{ textAlign: "start", color: "#952AE7", fontSize: "16px", fontWeight: 600, cursor: "pointer" }}
                >
                    <CopyOutlined /> Copy

                </Col>
            </Row>
        </Modal>
    );
};
