import { Authenticated, LayoutWrapper, Table, useTranslate } from "@pankod/refine";
import { FenBreadCrumb } from "components/BreadCrumb/BreadCrumb";
import { PageContainer } from "components/pageContainer/pageContainer";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { PaginationConstant } from "../../Constanst";
import { localizationHelper } from "../../helpers/globalizationHelper";
import { Pagination } from "../../services/core/Pagination";
import { NotificationService } from "../../services/services/NotificationService";
import { NotificationFilterComponent } from "./NotificationFilterComponent";

interface NotificationsProps {}

export const Notifications: FunctionComponent<NotificationsProps> = (props: NotificationsProps) => {
    const [pagination, setPagination] = useState<Pagination>({
        current: PaginationConstant.initialPage,
        pageSize: PaginationConstant.initialPageSize,
    });
    const [filterValues, setFilterValues] = useState<any>();
    const [dataLoading, setDataLoading] = useState(false);
    const [data, setData] = useState<any>([]);
    const t = useTranslate();

    const [search, setSearch] = useState<boolean>(true);

    useEffect(() => {
        if (search) {
            fetchData();
            resetCount();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const resetCount = () => {
        NotificationService.resetCount({ acceptLanguage: localizationHelper.getLocale() }).then((resp) => {
            console.log(resp);
        });
    };

    const fetchData = () => {
        setDataLoading(true);
        NotificationService.searchNotifications({
            body: prepareFilterRequest(),
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setDataLoading(false);
            setData(resp?.data);
            setPagination({
                current: resp?.pageInfo?.pageNumber,
                pageSize: resp?.pageInfo?.pageSize,
                total: resp?.pageInfo?.totalItemCount,
            });
            setSearch(false);
        });
    };

    const doFilter = (values: any) => {
        setPagination({
            current: PaginationConstant.initialPage,
            pageSize: PaginationConstant.initialPageSize,
        });
        setFilterValues(values);
        setSearch(true);
    };

    const prepareFilterRequest = () => {
        const notificationSearch: any = {};

        if (filterValues) {
            notificationSearch.notification =
                filterValues.notification !== null && filterValues.notification !== undefined ? filterValues.notification : null;
            notificationSearch.startDate =
                filterValues.date !== undefined && filterValues.date[0] ? new Date(filterValues.date[0]).toDateString() : null;
            notificationSearch.endDate =
                filterValues.date !== undefined && filterValues.date[1] ? new Date(filterValues.date[1]).toDateString() : null;

            notificationSearch.pageNumber = pagination.current;
            notificationSearch.pageSize = pagination.pageSize;
        } else {
            notificationSearch.notification = null;
            notificationSearch.endDate = null;
            notificationSearch.startDate = null;
            notificationSearch.pageNumber = pagination.current;
            notificationSearch.pageSize = pagination.pageSize;
        }
        return notificationSearch;
    };

    const handleTableChange = (page: any) => {
        setPagination({
            current: page.current,
            pageSize: page.pageSize,
            total: page.total,
        });
        setSearch(true);
    };

    const columns = [
        {
            title: <span className="table-font">{t("global.notification")}</span>,
            dataIndex: "id",
            key: "id",
            render: (text: string, row: any, index: number) => (
                <div>
                    <p>{moment.utc(row.createdOn, "DD/MM/YYYY HH:mm").local().format("YYYY-MM-DD HH:mm")}</p>
                    <span
                        style={{ fontSize: 13, fontWeight: 700 }}
                        dangerouslySetInnerHTML={templateControl(row.notificationSetting.template, row)}
                    ></span>
                </div>
            ),
        },
    ];

    const templateControl = (template: string, row: any) => {
        let convertedTemplate = template.replace(
            "{campaign}",
            `<a class="table-font" href=${"/campaign/detail/" + row.campaignId}> ${row.campaign?.name}</a>`
        );
        convertedTemplate = convertedTemplate.replace(
            "{influencer}",
            `<a class="table-font" href=${"/influencers/detail/" + row.influencerId} > ${
                row.influencer?.firstName + " " + row.influencer?.lastName
            }</a>`
        );
        convertedTemplate = convertedTemplate.replace(
            "{brand}",
            `<a class="table-font" href=${"/brands/detail/" + row.brandId} > ${row.brand?.name} </a>`
        );
        convertedTemplate = convertedTemplate.replace("{socialMedia}", row.socialMediaDescription ? row.socialMediaDescription : "");
        if (row.customParams) {
            for (var i: number = 0; i < row.customParams.length; i++) {
                convertedTemplate = convertedTemplate.replace(`{${i}}`, row.customParams[i]);
            }
        }
        return { __html: convertedTemplate };
    };

    return (
        <Authenticated>
            <LayoutWrapper>
                <FenBreadCrumb items={[{ name: t("global.notification"), url: "" }]} />
                <PageContainer pageTitle={"Inbox"} helperContent={""}>
                    <NotificationFilterComponent count={pagination.total} doFilter={doFilter} />
                    <Table
                        columns={columns}
                        pagination={pagination}
                        dataSource={data}
                        loading={dataLoading}
                        onChange={(page) => handleTableChange(page)}
                        scroll={{ x: "100%" }}
                    />
                </PageContainer>
            </LayoutWrapper>
        </Authenticated>
    );
};
