import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover, Table, useTranslate } from "@pankod/refine";
import { Grid, Row } from "antd";
import { localizationHelper } from "helpers/globalizationHelper";
import { getCampaignLiterals } from "pages/campaign/list/Components/CampaignListHelper";
import { useEffect, useState } from "react";
import { CampaignType, PaginationConstant } from "../../../Constanst";
import { CampaignsService } from "../../../services";
import { Pagination } from "../../../services/core/Pagination";
import { MyCampaignsProps } from "../myCampaigns";
import MyCampaignsFilterComponent from "./myCampaignsFilterComponent";
import { Link } from "react-router-dom";
import { Money } from "services/models/money";

const style = { display: "flex", justifyContent: "end", alignItems: "flex-end", width: "100%" };

const ActiveCampaignsTab = (props: MyCampaignsProps) => {
    const [pagination, setPagination] = useState<Pagination>({
        current: PaginationConstant.initialPage,
        pageSize: PaginationConstant.initialPageSize,
    });

    const [dataLoading, setDataLoading] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [filterValues, setFilterValues] = useState<any>();
    const t = useTranslate();

    const breakpoint = Grid.useBreakpoint();
    const isMobile = !breakpoint.lg;

    const [search, setSearch] = useState<boolean>(true);

    useEffect(() => {
        if (search) fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        if (props.update) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.update]);

    const fetchData = () => {
        setDataLoading(true);
        CampaignsService.getCampaignInfluencersActive({
            body: prepareFilterRequest(),
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setDataLoading(false);
            setData(resp?.data?.data);
            setPagination({
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: resp?.data?.pageInfo.totalItemCount,
            });
            setSearch(false);
            props.setUpdate(false);
        });
    };

    const prepareFilterRequest = () => {
        let body = {
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
        };

        console.log(filterValues);

        if (filterValues) {
            if (filterValues.campaignName) {
                // @ts-ignore
                body.campaignName = filterValues.campaignName;
            }
            if (filterValues.campaignType) {
                // @ts-ignore
                body.campaignType = filterValues.campaignType;
            }
            if (filterValues.country) {
                // @ts-ignore
                body.countryId = filterValues.country;
            }
            if (filterValues.socialMediaPlatforms && filterValues.socialMediaPlatforms.length > 0) {
                // @ts-ignore
                body.socialMediaPlatforms = filterValues.socialMediaPlatforms;
            }
        }

        return body;
    };

    const getCampaignTranslate = (type: any) => {
        const localized = getCampaignLiterals(type);
        return t(localized);
    };

    const onPageChange = (page: any) => {
        setPagination({
            current: page.current,
            pageSize: page.pageSize,
            total: page.total,
        });
        setSearch(true);
    };

    const columns = [
        {
            title: <span className="table-font">{t("global.campaign-name")}</span>,
            dataIndex: "campaignName",
            key: "campaignName",
            render: (text: any, row: any) => (
                <Link to={"detail/" + row.id}>
                    <span className="clickable-span font-color">{text}</span>
                </Link>
            ),
        },
        {
            title: <span className="table-font">{t("global.brand", "Marka")}</span>,
            dataIndex: "brandName",
            key: "brandName",
            render: (text: any, row: any) => (
                <Link to={"/brands/detail/" + row.brandId}>
                    <span className="clickable-spans font-color">{text}</span>
                </Link>
            ),
        },
        {
            title: <span className="table-font">{t("global.campaignType")}</span>,
            dataIndex: "campaignTypeHash",
            key: "campaignTypeHash",
            render: (text: CampaignType) => {
                if (text === CampaignType.action) {
                    return (
                        <Popover
                            content={<div>Açıklama metni gelecek.</div>}
                            placement="bottomRight"
                            trigger={isMobile ? "click" : "hover"}
                        >
                            {getCampaignTranslate(text)} <InfoCircleOutlined />
                        </Popover>
                    );
                } else {
                    return <span>{getCampaignTranslate(text)}</span>;
                }
            },
        },
        {
            title: <span className="table-font">{t("global.start")}</span>,
            dataIndex: "startDate",
            key: "startDate",
        },
        {
            title: <span className="table-font">{t("global.end")}</span>,
            dataIndex: "endDate",
            key: "endDate",
            render: (text: string, row: any) => {
                if (row.campaignTypeHash === CampaignType.amazon) {
                    return " - ";
                } else {
                    return text;
                }
            },
        },
        {
            title: <span className="table-font">{t("campaign.create.step3.amountByInfluencerLabell")}</span>,
            dataIndex: "balancePerInfluencer",
            key: "balancePerInfluencer",
            render: (balancePerInfluencer: string) => <>{new Money(balancePerInfluencer).toStringWithCurrency()}</>,
        },
        {
            title: <span className="table-font">{t("global.unit-price")}</span>,
            dataIndex: "unitPrice",
            key: "unitPrice",
            render: (unitPrice: string, row: any) => <>{new Money(unitPrice).toStringWithCurrency(row.currency === 1 ? "$" : "₺")}</>,
        },
        {
            title: <span className="table-font">{t("global.earningFromConversions")}</span>,
            dataIndex: "earningPrice",
            key: "earningPrice",
            render: (earningPrice: string, row: any) => <>{new Money(earningPrice).toStringWithCurrency(row.currency === 1 ? "$" : "₺")}</>,
        },
        {
            title: "",
            dataIndex: "",
            key: "",
            render: (text: any, row: any) => {
                return (
                    <Link to={"detail/" + row.id}>
                        <span style={{ cursor: "pointer", color: "#952AE7" }}>{t("global.performanceView")}</span>
                    </Link>
                );
            },
        },
    ];

    const filter = (values: any) => {
        setFilterValues(values);
        setSearch(true);
    };

    return (
        <>
            <Row style={style}>
                <MyCampaignsFilterComponent filter={filter} totalCount={pagination.total} />
            </Row>
            <hr />
            <Table
                style={{ overflow: "scroll" }}
                pagination={pagination}
                loading={dataLoading}
                onChange={(page) => onPageChange(page)}
                columns={columns}
                dataSource={data}
            />
        </>
    );
};

export default ActiveCampaignsTab;
